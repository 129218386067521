import React, { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import "./TransactionMessage.css";
import { useLocation, useNavigate } from "react-router-dom";
import * as API from "../../util/url";
import { MdOutlineArrowBack } from "react-icons/md";
import { FaCheck } from "react-icons/fa";
import { toast } from "react-toastify";
import Moment from "moment";
import SolonaPay from "../SolonaPay/SolonaPay";
const TransactionMessage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [solonaPay, setSolonaPay] = useState(false);
  const [qrCode, setQrCode] = useState({
    url: null,
    ref: null,
    domainName: "",
    buyNowPrice: 0,
  });

  const [messageData, setMessageData] = useState({});
  const [message, setMessage] = useState("");
  const [offer, setOffer] = useState(0);
  console.log(location, "loc");

  const solonaPayHandler = async () => {
    console.log(messageData.payment?.split("/")[2]);
    const id = messageData.payment?.split("/")[2];
    try {
      let response = await API.solGetQrCode({ paymentId: id });
      console.log(response.data.map, "rsp");
      setQrCode({
        ...qrCode,
        url: response.data.map.url,
        refer: response.data.map.ref,
      });
      setSolonaPay(true);
    } catch (error) {}
  };

  const viewMessageHandler = async () => {
    try {
      let response = await API.getMakeOfferMessageDashboard({
        makeId: location.state,
      });
      setMessageData(response.data.map);
      setQrCode({
        ...qrCode,
        domainName: response.data.map.productName,
        buyNowPrice: response.data.map.buyNowPrice,
      });

      if (response.data.success === true) {
        scrollDown();
      }
    } catch (error) {
      console.log(error, "hye");
    }
  };
  const StatusMessage = async (offerType, message) => {
    document.getElementById("Message").value = "";
    try {
      let response = await API.sendMakeOfferMessageDashboard({
        id: location.state,
        offerType,
        message,
      });

      setMessageData(response.data.map);
      if (response.data.map.status === true) {
        viewMessageHandler();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const OfferApiHandler = async (offerType, message) => {
    document.getElementById("Message1").value = "";
    try {
      let response = await API.makeOfferBuyer({
        makeId: location.state,
        offerType: "offer",
        message: "Offer",
        price: offer,
      });

      setMessageData(response.data.map);
      if (response.data.map.status === true) {
        viewMessageHandler();
      } else if (response.data.map.status === false) {
        toast(response.data.map.message, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "colored",
        });
        viewMessageHandler();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const CounterOfferApiHandler = async () => {
    try {
      let response = await API.sendMakeOfferMessageDashboard({
        id: location.state,
        offerType: "counter",
        message: "Counter Offer",
        price: offer,
      });

      setMessageData(response.data.map);
      if (response.data.map.status === true) {
        viewMessageHandler();
      } else if (response.data.map.status === false) {
        toast(response.data.map.message, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "colored",
        });
        viewMessageHandler();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const SendMessage = async () => {
    document.getElementById("Message").value = "";

    try {
      let response;
      messageData.role === "buyer"
        ? (response = await API.makeOfferBuyer({
            makeId: location.state,
            message: message,
            offerType: "",
            price: offer,
          }))
        : (response = await API.sendMakeOfferMessageDashboard({
            id: location.state,
            message: message,
            offerType: "",
          }));

      setMessageData(response.data.map);
      if (response.data.map.status === true) {
        viewMessageHandler();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const messageContain = (e) => {
    setMessage(e.target.value);
  };

  const offerHandler = (e) => {
    setOffer(e.target.value);
  };
  function scrollDown() {
    setTimeout(() => {
      if (location.state !== null) {
        document.getElementById("chatBox").scrollTop =
          document.getElementById("chatBox").scrollHeight;
      }
    }, 20);
  }

  useEffect(() => {
    viewMessageHandler();
    scrollDown();
  }, []);
  return (
    <div className="container">
      <div className="chatData">
        <section className="msger">
          <header className="msger-header">
            <div className="msger-header-title d-flex">
              <div
                onClick={() => navigate("/dashboard/offer")}
                style={{ paddingRight: "10px", cursor: "pointer" }}
              >
                {" "}
                <MdOutlineArrowBack className="fs-3" />
              </div>
              <i className="fas fa-comment-alt" /> {messageData.productName}
            </div>
            <div className="msger-header-options">
              {/* <span>
                      <i className="fas fa-cog" />
                    </span> */}
              {messageData.timeRemaining !== "Closed" && (
                <button
                  type="button"
                  onClick={() => solonaPayHandler()}
                  className="btn msger-send-btn"
                  style={{
                    color: "white",
                    margin: "0px",
                    background: "#224bee",
                  }}
                >
                  Buy Now at ${messageData.buyNowPrice}
                </button>
              )}
            </div>
          </header>
          <main id="chatBox" className="msger-chat">
            {messageData?.makeOffer?.map((data) => {
              if (data.sendBy === messageData.role) {
                return (
                  <>
                    <div className="msg right-msg">
                      <div
                        className="msg-img1"
                        style={{
                          backgroundImage:
                            "url(https://image.flaticon.com/icons/svg/145/145867.svg)",
                        }}
                      >
                        {messageData.sellerName?.split("")[0]}
                      </div>

                      <div className="msg-bubble">
                        <div className="msg-text text-start designRight">
                          <div>{data.subject}</div>
                          <div>
                            {" "}
                            {data.subject == "Message"
                              ? data.message
                              : "$" + data.price}
                          </div>
                        </div>
                        <div className="msg-info">
                          <div
                            className="msg-info-name "
                            style={{ color: "gray" }}
                          >
                            {messageData.userName}
                          </div>
                          <div className="msg-info-time">
                            {Moment(data.time).format("ll")}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              } else {
                return (
                  <div className="msg left-msg">
                    <div
                      className="msg-img"
                      style={{
                        backgroundImage:
                          "url(https://res.cloudinary.com/dowsba1cv/image/upload/v1631884636/digitalkingdom_mch40q.png)",
                      }}
                    />
                    <div className="msg-bubble">
                      <div className="msg-text text-start designLeft">
                        <div>{data.subject}</div>
                        <div>
                          {" "}
                          {data.subject == "Message"
                            ? data.message
                            : "$" + data.price}
                        </div>
                      </div>
                      <div className="msg-info">
                        <div
                          className="msg-info-name "
                          style={{ color: "gray" }}
                        >
                          {data.sendBy === "seller"
                            ? messageData.sellerName
                            : "BestUrls"}
                        </div>
                        <div className="msg-info-time">
                          {Moment(data.time).format("ll")}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
            })}
          </main>
          {messageData.timeRemaining !== "Closed" ? (
            <form className="msger-inputarea">
              <input
                id="Message"
                type="text"
                className="msger-input"
                placeholder="Enter your message..."
                onChange={(e) => messageContain(e)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    SendMessage(message);
                  }
                }}
              />
              <button
                type="button"
                onClick={() => SendMessage()}
                className="msger-send-btn"
                style={{ color: "white" }}
              >
                Send
              </button>

              {messageData.role === "buyer" ? (
                <>
                  <Dropdown className="d-flex p-0 ">
                    <Dropdown.Toggle
                      className="msger-send-btn"
                      variant="success"
                      id="dropdown-basic"
                    >
                      Offer
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <input
                        type="number"
                        id="Message1"
                        className="msger-input"
                        onChange={(e) => offerHandler(e)}
                      />

                      <div className="text-center">
                        <button
                          type="button"
                          onClick={() => OfferApiHandler()}
                          className="msger-send-btn"
                          style={{ color: "white", margin: "0px" }}
                        >
                          Send Offer
                        </button>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </>
              ) : (
                <>
                  <Dropdown className="d-flex p-0 send-mobile-btn">
                    <Dropdown.Toggle
                      className="msger-send-btn"
                      variant="success"
                      id="dropdown-basic"
                    >
                      Counter Offer
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <input
                        type="number"
                        id="Message1"
                        className="msger-input"
                        onChange={(e) => offerHandler(e)}
                      />

                      <div className="text-center">
                        <button
                          type="button"
                          onClick={() => CounterOfferApiHandler()}
                          className="msger-send-btn"
                          style={{ color: "white", margin: "0px" }}
                        >
                          Send Offer
                        </button>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                  <button
                    type="button"
                    onClick={() => StatusMessage("accept", "Offer Accepted")}
                    className="msger-send-btn"
                    style={{ color: "white" }}
                  >
                    Accept
                  </button>
                  <button
                    type="button"
                    onClick={() => StatusMessage("reject", "Offer Rejected")}
                    className="msger-send-btn"
                    style={{ color: "white", background: "#F35D5D" }}
                  >
                    Reject
                  </button>
                </>
              )}
            </form>
          ) : (
            <div className="text-end p-2">
              <button
                type="button"
                className="btn btn-danger"
                style={{ width: "fit-content" }}
              >
                Offer Closed
              </button>
            </div>
          )}
        </section>
      </div>
      <SolonaPay
        show={solonaPay}
        onHide={() => setSolonaPay(false)}
        qrCode={qrCode}
      />
    </div>
  );
};

export default TransactionMessage;
