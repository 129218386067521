import { configureStore } from "@reduxjs/toolkit";

import authSlice from "./slices/authSlice";
import bidDomainUrlSlice from "./slices/bidDomainUrlSlice";

export const store = configureStore({
    reducer:{
        auth:authSlice,
        bid:bidDomainUrlSlice
    }
})