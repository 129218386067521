import React from "react";
import { NavLink } from "react-router-dom";
import { FaArrowRightLong } from "react-icons/fa6";

const HomeBlog = () => {
  const userCred = localStorage.getItem("bestUrl");

  return (
    <div>
      <div id="blog">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-12 text-center">
              <h2 className="blog-heading">Latest blog</h2>
              <div className="line" />
            </div>
          </div>
          <div className="row">
            {/* <div className="col-lg-6 col-md-12 col-12">
        <div className="blog-block">
          <div className="blog-img">
            <img
              src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1706349488/what_is_domain_and_why_you_need_it_for_a_business_1_ut47wg.png"
              alt="blog"
              width="100%"
              style={{ objectFit: "cover" }}
            />
          </div>
          <div className="blog-info">
            <h3>What a Domain is and Why you need it for your business?</h3>
            <p>
             
A domain is a unique web address used to locate resources online, like websites or email servers. Having your own domain enhances professionalism, credibility, and brand identity, distinguishing your online presence from others.
            </p>
            <a href="/blog" className="more">
              Read More <span><img src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1707395200/icons8-double-right_jt0g1q.gif" alt="" /></span>
            </a>
          </div>
        </div>
      </div> */}
            <div className="col-lg-4 col-md-12 col-12">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-12">
                  <div className="second-blog blog-sec">
                    <div className="">
                      <div className="">
                        <div className="second-blog-img_last">
                          <img
                            src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1706349489/why_does_your_business_needs_a_domain_email_address_mtgtfv.png"
                            alt="blog"
                            width="100%"
                          />
                        </div>
                      </div>
                      <div className="">
                        <div className="second-blog-info">
                          <h3>
                            Why does your business need a Domain email address ?
                          </h3>
                          {/* <p>
                            A domain email address for your business enhances
                            professionalism, instills trust, and reinforces
                            legitimacy in your communication with clients,
                            partners, and customers.
                          </p> */}
                          <NavLink
                            to={userCred !== null ? "/blog" : "/signin"}
                            className="more"
                          >
                            Read More{" "}
                            <span>
                              {/* <img
                                src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1715431160/Animation---1715429909861-1-ezgif.com-optimize_nnipk4.gif"
                                alt=""
                              /> */}
                              {/* <img
                                src=" https://res.cloudinary.com/dbtsrjssc/image/upload/v1712732682/icons8-play-30_ux4ngf_1_1_nth3ic.png"
                                alt=""
                              /> */}
                              <FaArrowRightLong />
                            </span>
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 col-12">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-12">
                  <div className="second-blog blog-sec">
                    <div className="">
                      <div className="">
                        <div className="second-blog-img_last">
                          <img
                            src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1706349488/what_is_domain_and_why_you_need_it_for_a_business_1_ut47wg.png"
                            alt=""
                            width="100%"
                          />
                        </div>
                      </div>
                      <div className="">
                        <div className="second-blog-info">
                          <h3>
                            Why does your business need a Domain email address ?
                          </h3>
                          {/* <p>
                            A domain email address for your business enhances
                            professionalism, instills trust, and reinforces
                            legitimacy in your communication with clients,
                            partners, and customers.
                          </p> */}
                          <NavLink
                            to={userCred !== null ? "/blog" : "/signin"}
                            className="more"
                          >
                            Read more{" "}
                            <span>
                              {/* <img
                                src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1715431160/Animation---1715429909861-1-ezgif.com-optimize_nnipk4.gif"
                                alt=""
                              /> */}
                              {/* <img
                                src=" https://res.cloudinary.com/dbtsrjssc/image/upload/v1712732682/icons8-play-30_ux4ngf_1_1_nth3ic.png"
                                alt=""
                              /> */}
                              <FaArrowRightLong />
                            </span>
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 col-12">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-12">
                  <div className="second-blog blog-sec">
                    <div className="">
                      <div className="">
                        <div className="second-blog-img_last">
                          <img
                            src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1706349488/HOW_TO_BUY_PREMIUM_DOMAIN_fyyeab.png"
                            alt="blog"
                            width="100%"
                          />
                        </div>
                      </div>
                      <div className="">
                        <div className="second-blog-info">
                          <h3>
                            Why does your business need a Domain email address ?
                          </h3>
                          {/* <p>
                            A domain email address for your business enhances
                            professionalism, instills trust, and reinforces
                            legitimacy in your communication with clients,
                            partners, and customers.
                          </p> */}
                          <NavLink
                            to={userCred !== null ? "/blog" : "/signin"}
                            className="more"
                          >
                            Read more{" "}
                            <span>
                              {/* <img
                                src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1715431160/Animation---1715429909861-1-ezgif.com-optimize_nnipk4.gif"
                                alt=""
                              /> */}
                              {/* <img
                                src=" https://res.cloudinary.com/dbtsrjssc/image/upload/v1712732682/icons8-play-30_ux4ngf_1_1_nth3ic.png"
                                alt=""
                              /> */}
                              <FaArrowRightLong />
                            </span>
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeBlog;
