import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import * as API from "../../util/url";

const FeatureListing = () => {
  const [listData, setListData] = useState([]);
  const navigate = useNavigate();

  const listingData = async () => {
    try {
      let response = await API.getHomePageData();
      setListData(response.data?.map?.featured?.Domain);
      console.log(response.data?.map?.featured?.Domain, "rfd");
    } catch (error) {
      console.log(error);
    }
  };

  const listingHandler = (name) => {
    if (localStorage.getItem("bestUrl") !== null) {
      navigate("/offer-preview", {
        state: name,
      });
    } else {
      navigate("/signin");
    }
  };

  useEffect(() => {
    listingData();
  }, []);
  return (
    <div>
      <div id="feature-listing">
        <div className="container">
          <div className="row">
            <div className="col-lg-12-col-md-12 col-12 text-center">
              <h2 className="feature-heading">Featured listing</h2>
              <div className="line" />
            </div>
          </div>
          {/* ++++++++ */}
          <div className="row mt-3">
            {/* {listData?.map((data, index) => {
              return (
                <div key={index} className="col-lg-3 col-md-6 col-12 mb-5">
                  <div className="feature-block-1">
                  

                    <div className="domainCover">
                      <div className="feature-para-1">{data.productName}</div>
                      <p>{data.age}</p>

                    
                    </div>

                    <div className="price">
                      <div>
                        <h4>Starting at</h4>
                        <p>${data.buyNowPrice}</p>
                      </div>
                      <div
                        className="preview"
                        style={{ cursor: "pointer" }}
                        onClick={() => listingHandler(data.productName)}
                      >
                        Buy Now
                      </div>
                    </div>
                  </div>
                </div>
              );
            })} */}
            <div className="col-lg-3 col-md-6 col-12 mb-5">
              <div className="feature-block-1">
                <div className="domainCover">
                  <div className="feature-para-1">wed.com</div>
                  <p>10 years</p>
                </div>

                <div className="price">
                  <div>
                    <h4>Starting at</h4>
                    <p>$22</p>
                  </div>
                  <div
                    className="preview"
                    style={{ cursor: "pointer" }}
                    // onClick={() => listingHandler(data.productName)}
                  >
                    Buy Now
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12 mb-5">
              <div className="feature-block-1">
                <div className="domainCover">
                  <div className="feature-para-1">wed.com</div>
                  <p>10 years</p>
                </div>

                <div className="price">
                  <div>
                    <h4>Starting at</h4>
                    <p>$22</p>
                  </div>
                  <div
                    className="preview"
                    style={{ cursor: "pointer" }}
                    // onClick={() => listingHandler(data.productName)}
                  >
                    Buy Now
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12 mb-5">
              <div className="feature-block-1">
                <div className="domainCover">
                  <div className="feature-para-1">wed.com</div>
                  <p>10 years</p>
                </div>

                <div className="price">
                  <div>
                    <h4>Starting at</h4>
                    <p>$22</p>
                  </div>
                  <div
                    className="preview"
                    style={{ cursor: "pointer" }}
                    // onClick={() => listingHandler(data.productName)}
                  >
                    Buy Now
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12 mb-5">
              <div className="feature-block-1">
                <div className="domainCover">
                  <div className="feature-para-1">wed.com</div>
                  <p>10 years</p>
                </div>

                <div className="price">
                  <div>
                    <h4>Starting at</h4>
                    <p>$22</p>
                  </div>
                  <div
                    className="preview"
                    style={{ cursor: "pointer" }}
                    // onClick={() => listingHandler(data.productName)}
                  >
                    Buy Now
                  </div>
                </div>
              </div>
            </div>
            <br />
          </div>
          {/* ++++++++++++ */}
          {/* <div className="row">
            <div className="col-lg-3 col-md-6 col-12 mt-4">
              <div className="feature-block-1">
               
                <div className="domainCover">
                  <p className="feature-para-1">we.com</p>
                 
                </div>
            
                <div className="price">
                  <h4>Current Price</h4>
                  <p>$0</p>
                  <div
                    className="preview"
                    style={{ cursor: "pointer" }}
                   
                  >
                    Preview
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12 mt-4">
              <div className="feature-block-1">
               
                <div className="domainCover">
                  <p className="feature-para-1">are.com</p>
                
                </div>
              
                <div className="price">
                  <h4>Current Price</h4>
                  <p>$0</p>
                  <div
                    className="preview"
                    style={{ cursor: "pointer" }}
                    
                  >
                    Preview
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12 mt-4">
              <div className="feature-block-1">
               
                <div className="domainCover">
                  <p className="feature-para-1">pa.com</p>
                 
                </div>
               
                <div className="price">
                  <h4>Current Price</h4>
                  <p>$0</p>
                  <div
                    className="preview"
                    style={{ cursor: "pointer" }}
                  
                  >
                    Preview
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12 mt-4">
              <div className="feature-block-1">
               
                <div className="domainCover">
                  <p className="feature-para-1">pa.com</p>
               
                </div>
              
                <div className="price">
                  <h4>Current Price</h4>
                  <p>$0</p>
                  <div
                    className="preview"
                    style={{ cursor: "pointer" }}
                   
                  >
                    Preview
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default FeatureListing;
