import search from "../../image/search_domain_new.png";
import buydomain from "../../image/buy_domain_new.png";
import getdomain from "../../image/claim_domain_new.png";

const HomeProcess = () => {
  return (
    <div>
      <div id="buy-sell">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-12 text-center">
              <h2 className="buy-sell-heading">
                {/* How to purchase &amp; sell domains through BestUrls ? */}
                how It Works
              </h2>
              <div className="line" />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-4 col-12">
              <div className="key-block">
                <div
                  className="key-img"
                  style={{ textAlign: "-webkit-center" }}
                >
                  <img
                    src={search}
                    // src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1706188698/Asset_8_rg9cvt.png"
                    alt="agree"
                  />
                  <p className="key-block-para">Search Domain</p>
                  <p className="key-block-text">
                    Use our search and look for the domain you want in our DNGH
                    database
                  </p>
                </div>
              </div>
            </div>
            {/* <div className="col-lg-3 col-md-6 col-12">
              <div className="key-block">
                <div
                  className="key-img"
                  style={{ textAlign: "-webkit-center" }}
                >
                  <img
                    src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1706188698/Asset_9_golmzh.png"
                    alt="submit"
                  />
                  <p className="key-block-para">Find your dream domain</p>
                </div>
              </div>
            </div> */}
            <div className="col-lg-4 col-md-4 col-12">
              <div className="key-block">
                <div
                  className="key-img"
                  style={{ textAlign: "-webkit-center" }}
                >
                  <img
                    src={buydomain}
                    // src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1706188698/Asset_10_gjivyn.png"
                    alt="deliver"
                  />
                  <p className="key-block-para">Compare And Buy</p>
                  <p className="key-block-text">
                    Once you find a domain that suits your needs, you can buy it
                    easily and securely!
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-12">
              <div className="key-block">
                <div
                  className="key-img"
                  style={{ textAlign: "-webkit-center" }}
                >
                  <img
                    src={getdomain}
                    // src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1706188698/Asset_7_yhjvbd.png"
                    alt="done"
                  />
                  <p className="key-block-para"> Get Domain</p>
                  <p className="key-block-text">
                    Congratulations! You can now make full use of your new
                    domain name!
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="row">
            <div className="col-lg-12 col-md-12 col-12 text-center">
              <a href="#" className="preview" style={{ display: "inline" }}>
                Get Started Now
              </a>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default HomeProcess;
