import React, { useEffect, useState } from "react";
import "./DashBoard.css";
import * as API from "../util/url";

// import Footer from "../Footer";
import { NavLink } from "react-router-dom";
const DashBoard = () => {
  const [domainData, setDomainData] = useState({});

  const getDashboardDataHandler = async () => {
    try {
      let response = await API.getDashboardData();
      console.log(response, "rsp");
      setDomainData(response.data.map);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDashboardDataHandler();
  }, []);
  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12 ">
            <div className="dash_heading">
              <h2>Dashboard</h2>
              {/* <hr /> */}
            </div>

            <div className="container">
              <div className="row">
                <div className="col-lg-3 col-md-6 col-12 ">
                  <div
                    className="dash"
                    // style={{ backgroundColor: "aliceblue" }}
                  >
                    <div className="row">
                      <NavLink className="countLink">Total Revenue</NavLink>
                      <div className="col-6">
                        <div className="dash_img">
                          <img
                            src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1715605888/Group_389_1_n0lfur.png"
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="dash_info">
                          <div className="count">
                            <h6>${domainData.revenue}</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-12 ">
                  <div
                    className="dash"
                    // style={{ backgroundColor: "#e6fffa" }}
                  >
                    <div className="row">
                      <NavLink className="countLink">Sold</NavLink>
                      <div className="col-6">
                        <div className="dash_img">
                          <img
                            src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1715605888/Group_388_1_vddlmh.png"
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="dash_info">
                          <div className="count">
                            <h6>{domainData?.sold}</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="col-lg-3 col-md-6 col-12 ">
                  <div
                    className="dash"
                    //  style={{ backgroundColor: "#fbf2ef" }}
                  >
                    <div className="row">
                      <NavLink className="countLink">Auction Listing's</NavLink>
                      <div className="col-6">
                        <div className="dash_img">
                          <img
                            src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1715605888/Group_391_1_syl4xs.png"
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="dash_info">
                          <div className="count">
                            <h6>{domainData?.auctionListings}</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="col-lg-3 col-md-6 col-12 ">
                  <div
                    className="dash"
                    // style={{ backgroundColor: "#fef5e5" }}
                  >
                    <div className="row">
                      <NavLink className="countLink">Asset for Sale</NavLink>
                      <div className="col-6">
                        <div className="dash_img">
                          <img
                            src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1715605888/Group_390_1_kqsbdu.png"
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="dash_info">
                          <div className="count">
                            <h6>{domainData?.assetsForSale}</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="container mt-3">
              <div className="row">
                <div className="col-lg-6 col-md-6.col-6">
                  <div className="dash">
                    <h6 className="revenue_heading">Revenue</h6>
                    <h6 className="revenue_data">Revenue/Month</h6>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6.col-6">
                  <div className="dash">
                    <h6 className="revenue_heading">Transaction</h6>
                    <h6 className="revenue_data">Transaction/Month</h6>
                  </div>
                </div>
              </div>
            </div>

            <div className="container mt-3">
              <div className="row">
                <div className="col-lg-6 col-md-6.col-6">
                  <div className="dash">
                    <h6 className="revenue_heading">Recent Listings</h6>
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th scope="col">Sr. no</th>
                          <th scope="col">Product Name</th>
                          <th scope="col">Type</th>
                          <th scope="col">Date</th>
                        </tr>
                      </thead>
                      <tbody></tbody>
                    </table>
                    <div>No Record Found</div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6.col-6">
                  <div className="dash">
                    <h6 className="revenue_heading">Recent Transactions</h6>
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th scope="col">Sr. no</th>
                          <th scope="col">Product Name</th>
                          <th scope="col">Type</th>
                          <th scope="col">Date</th>
                        </tr>
                      </thead>
                      <tbody></tbody>
                    </table>
                    <div>No Record Found</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <Footer /> */}
    </div>
  );
};

export default DashBoard;
