import React, { useEffect, useRef } from 'react'
const CopyToClipboard = ({ clipboard }) => {
    const copy = useRef()
 
    // useEffect(()=>{navigator.clipboard.writeText(data)},[data])
    // navigator.clipboard.writeText(data)
    const CopyToClipboard = () => copy.current.innerHTML = 'Copied';
    const copyOver = () => copy.current.style.display = 'block';
    const copyLeave = () => {
        copy.current.style.display = 'none';
        copy.current.innerHTML = 'Copy';
    }

    return <div style={{ position: 'relative' }}  >
        <div onClick={()=>{CopyToClipboard();
            clipboard();
        }} onMouseOver={copyOver} onMouseLeave={copyLeave}>
            <svg
                // onClick={copyAddress}
                className="ms-1"
                style={{ cursor: 'pointer' }}
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 24 24"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M20 2H10c-1.103 0-2 .897-2 2v4H4c-1.103 0-2 .897-2 2v10c0 1.103.897 2 2 2h10c1.103 0 2-.897 2-2v-4h4c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zM4 20V10h10l.002 10H4zm16-6h-4v-4c0-1.103-.897-2-2-2h-4V4h10v10z"></path>
            </svg>
        </div>
        <span className='bg-dark text-white p-2 rounded' style={{ display: 'none', position: 'absolute', top: '-9px', left: '25px', width: 'max-content' }} ref={copy}>Copy</span>
    </div>
}

export default CopyToClipboard