import React,{useState} from 'react'
import { Field, ErrorMessage } from 'formik'
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import TextError from "./TextError";

const CustomPasswordInput = () => {
  const [hide ,setHide] = useState(true); 

    return (
        <div>
            {/* <label htmlFor={'password'} className='customLabel' >Password</label> */}
            <div className="eyeCover">
                <Field className="form-control" type={hide ? 'password' : 'text'} id={'password'} name={'password'}  placeholder={'Password'}  autoComplete="on" />
                <span className="eye" onClick={() => setHide(!hide)}> {hide ? <IoMdEyeOff /> : <IoMdEye />} </span>
            </div>

            <ErrorMessage name='password' component={TextError} />
            <br />

        </div>
    )
}

export default CustomPasswordInput
