import React, { useState, useEffect } from "react";
import * as API from "../../../util/url";
import PageSize from "../../../Common/PageSize";
import ProjectPagination from "../../../Common/Pagination/ProjectPagination";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import ApiCaller from "../../../util/ApiCaller";

const AdminDomainPortfolio = () => {
  const [accountData, setAccountData] = useState([]);
  const navigate = useNavigate();
  const [apiInfo, setApiInfo] = useState({
    path: "",
    redirectUrl: "",
  });

  const [pageSize, setPageSize] = useState("10");
  const [pageCount, setPageCount] = useState(0);
  const [dataCount, setDataCount] = useState(0);
  const getAllAccount = async (pageNumber = 1, keyword = "", status, order) => {
    try {
      let response = await API.getAllDomainPortfolio({
        pageNumber: pageNumber,
        size: pageSize,
        keyword: keyword,
      });
      console.log(response, "rvm");
      setAccountData(response.data.map.domains);
      setPageCount(response.data.map.size / pageSize);
      setDataCount(response.data.map.size);
    } catch (error) {
      console.log(error);
    }
  };

  const handlePageSize = (data) => setPageSize(data);

  const editDomain = async (domainId) => {
    try {
      let response = await API.getProductData(domainId);
      console.log(response, "rsp");
      navigate("/domain-auction", { state: response.data.map.domain });
    } catch (error) {
      console.log(error);
    }
  };

  const spamUserHandler = async (data) => {
    console.log(data);
    try {
      let response = await API.verifyProduct(data);
    } catch (error) {
      console.log(error);
    }
  };

  const premiumHandler = async (data) => {
    console.log(data);
    try {
      let response = await API.setPremium(data);
    } catch (error) {
      console.log(error);
    }
  };
  const featureHandler = async (data) => {
    console.log(data);
    try {
      let response = await API.setFeature(data);
    } catch (error) {
      console.log(error);
    }
  };
  const topDomainHandler = async (data) => {
    console.log(data);
    try {
      let response = await API.setTopDomains(data);
    } catch (error) {
      console.log(error);
    }
  };

  const showcaseHandler = async (data) => {
    console.log(data);
    try {
      let response = await API.setShowcaseDomains(data);
    } catch (error) {
      console.log(error);
    }
  };

  const deleteHandler = async (data) => {
    console.log(data);
    try {
      let response = await API.deleteProduct(data);
      console.log(response.data.map.status);
    } catch (error) {
      console.log(error);
    }
  };

  const handleInputChange = (e, data, field) => {
    const { value } = e.target;
    console.log(data, field);
    setAccountData((prevData) => {
      const newData = [...prevData];
      newData[data][field] = value;
      return newData;
    });
  };

  const updateDomainPortfolioHandler = async (data) => {
    console.log(data, "dt");
    const { buyNowPrice, domainId, minimumPrice } = data;

    setApiInfo({
      path: () =>
        API.updateBuyNowDomains({
          domains: [
            {
              buyNowPrice,
              domainId,
              minimumPrice,
            },
          ],
        }),
      redirectUrl: "/power/dashboard/all-domain-portfolio",
    });
  };

  useEffect(() => {
    getAllAccount();
  }, [pageSize]);
  const keywordFilter = (searchData) => {
    getAllAccount(1, searchData);
  };

  return (
    <div className="admin-sidenav-options">
      <div className="page_tittle">
        <h3 className="px-3">Domain Portfolio</h3>
      </div>
      <div
        className="d-flex"
        style={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <div className="showing">
          <h6>Showing {pageSize} items</h6>
        </div>
        <div className="searchcol px-3">
          <input
            className="searchinput"
            type="text"
            placeholder="Search.. "
            onChange={(e) => keywordFilter(e.target.value)}
          />
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-12">
          <div className="table-container container table-responsive">
            <table className="table tablediv table-hover acutiontble table-striped">
              <thead className="headertable">
                <tr>
                  <th scope="col">Domain Name</th>
                  <th scope="col" style={{ width: "150px" }}>
                    BuyNow Price
                  </th>
                  <th scope="col" style={{ width: "150px" }}>
                    Minimum Price
                  </th>
                  <th scope="col">Creation Date</th>
                  <th scope="col"> Leads</th>
                  <th scope="col"> Owner Name</th>
                  <th scope="col"> Age</th>
                  <th scope="col"> Registrar</th>
                  <th scope="col"> Verification</th>
                  <th scope="col"> Premium </th>
                  <th scope="col"> Features</th>
                  <th scope="col"> showcase </th>
                  <th scope="col"> topDomain</th>
                  <th scope="col"> Deletes</th>
                  <th scope="col"> Action</th>
                </tr>
              </thead>
              <tbody>
                {accountData.map((data, index) => {
                  return (
                    <tr key={index}>
                      <td>{data.domainName}</td>
                      <td>
                        <div>
                          {" "}
                          <InputGroup className="priceWidth">
                            <InputGroup.Text id="basic-addon1">
                              $
                            </InputGroup.Text>
                            <Form.Control
                              type="number"
                              aria-describedby="basic-addon1"
                              defaultValue={data.buyNowPrice}
                              onChange={(e) =>
                                handleInputChange(e, index, "buyNowPrice")
                              }
                            />
                          </InputGroup>{" "}
                        </div>
                      </td>

                      <td>
                        <div>
                          {" "}
                          <InputGroup className="priceWidth">
                            <InputGroup.Text id="basic-addon1">
                              $
                            </InputGroup.Text>
                            <Form.Control
                              type="number"
                              aria-describedby="basic-addon1"
                              defaultValue={data.minimumPrice}
                              onChange={(e) =>
                                handleInputChange(e, index, "minimumPrice")
                              }
                            />
                          </InputGroup>{" "}
                        </div>
                      </td>
                      <td>{moment(data.creationDate).format("ll")}</td>
                      <td>{data.leads}</td>
                      <td>{data.owner}</td>
                      <td>{data.age}</td>
                      <td>{data.registar}</td>
                      <td>
                        <label className="switch">
                          <input
                            type="checkbox"
                            defaultChecked={data.verified}
                            onClick={(e) =>
                              spamUserHandler({
                                id: data.domainId,
                                verify: e.target.checked,
                              })
                            }
                          />
                          <span className="slider round"></span>
                        </label>
                      </td>
                      <td>
                        <label className="switch">
                          <input
                            type="checkbox"
                            defaultChecked={data.premium}
                            onClick={(e) =>
                              premiumHandler({
                                id: data.domainId,
                                premium: e.target.checked,
                              })
                            }
                          />
                          <span className="slider round"></span>
                        </label>
                        {data.premium}
                      </td>
                      <td>
                        <label className="switch">
                          <input
                            type="checkbox"
                            defaultChecked={data.featured}
                            onClick={(e) =>
                              featureHandler({
                                id: data.domainId,
                                featured: e.target.checked,
                              })
                            }
                          />
                          <span className="slider round"></span>
                        </label>
                      </td>
                      <td>
                        <label className="switch">
                          <input
                            type="checkbox"
                            defaultChecked={data.showcase}
                            onClick={(e) =>
                              showcaseHandler({
                                domainId: data.domainId,
                                value: e.target.checked,
                              })
                            }
                          />
                          <span className="slider round"></span>
                        </label>
                      </td>
                      <td>
                        <label className="switch">
                          <input
                            type="checkbox"
                            defaultChecked={data.topDomain}
                            onClick={(e) =>
                              topDomainHandler({
                                domainId: data.domainId,
                                value: e.target.checked,
                              })
                            }
                          />
                          <span className="slider round"></span>
                        </label>
                      </td>
                      <td>
                        <label className="switch">
                          <input
                            type="checkbox"
                            defaultChecked={data.deleted}
                            onClick={(e) =>
                              deleteHandler({
                                id: data.domainId,
                                verify: e.target.checked,
                              })
                            }
                          />
                          <span className="slider round"></span>
                        </label>
                      </td>

                      <td
                        style={{ cursor: "pointer" }}
                        onClick={() => updateDomainPortfolioHandler(data)}
                      >
                        {" "}
                        <div className="button-update">Update</div>{" "}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div style={{ position: "relative", height: "80px" }}>
        {dataCount > pageSize && (
          <ProjectPagination
            handlePageClick={(e) => getAllAccount(e.selected + 1)}
            pageCount={pageCount}
          />
        )}

        <div className="pageSizeContainer">
          <PageSize
            title={`${pageSize} items`}
            handleData={(e) => handlePageSize(e)}
          />
        </div>
      </div>
      <ApiCaller apiData={apiInfo} />
    </div>
  );
};

export default AdminDomainPortfolio;
