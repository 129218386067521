import React from "react";
// import background from "../../image/webbg2_new.png";
import { IoCheckmarkDone } from "react-icons/io5";

const SellBuy = () => {
  return (
    <div>
      <div id="work">
        <div className="container buy-sell-section">
          {/* <div className="listing-img">
            <img src={background} alt="" style={{ height: "30rem" }} />
          </div> */}
          <div className="row">
            {/* <div className=" buy-domains"></div> */}
            <div
              className="col-lg-6 col-md-6 col-12 "
              // style={{ padding: "5rem 0 0 7rem" }}
            >
              {/* Buy Domain: Your go-to destination for acquiring valuable and concise internet domains. We help companies and startups find their ideal web addresses to enhance online presence, launch new products, and protect their brand. Explore our collection of premium domains perfect for marketing and specific campaigns. */}
              <div className="buyer">
                <h2>Domain Buyers ...</h2>
                <ul className="list">
                  <li>
                    <IoCheckmarkDone className="me-2" />
                    Companies and startups searching for their ideal web address
                  </li>
                  <li>
                    <IoCheckmarkDone className="me-2" />
                    Building an online presence for new products
                  </li>
                  <li>
                    {" "}
                    <IoCheckmarkDone className="me-2" />
                    for brand protection
                  </li>
                  <li>
                    {" "}
                    <IoCheckmarkDone className="me-2" />
                    valuable domain names
                  </li>
                  <li>
                    <IoCheckmarkDone className="me-2" />
                    Concise internet domains for marketing and specific
                    campaigns
                  </li>
                </ul>
                {/* <a href="#" className="buy">
                  Domain Buyer...
                </a> */}
              </div>
            </div>

            <div
              className="col-lg-6 col-md-6 col-12 "
              // style={{ padding: "5rem 4rem" }}
            >
              <div className="buyer">
                <h2>Domain Seller ...</h2>
                <ul className="list">
                  <li>
                    {" "}
                    <IoCheckmarkDone className="me-2" />
                    Turning unused web addresses into money
                  </li>
                  <li>
                    {" "}
                    <IoCheckmarkDone className="me-2" />
                    Using a Broker to market premium domains
                  </li>
                  <li>
                    {" "}
                    <IoCheckmarkDone className="me-2" />
                    Earning money with Domain Parking
                  </li>
                  <li>
                    {" "}
                    <IoCheckmarkDone className="me-2" />
                    Professional trading of internet domains
                  </li>
                  <li>
                    <IoCheckmarkDone className="me-2" />
                    Launching a new domain extension/Top Level Domain (gTLD)
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SellBuy;
