import React from 'react'

const TextError = (props) => {
  return (
    <div className='errorMsg mt-1' style={{color:'red', textAlign:'left',fontSize:'13px'}}>
        {props.children}
    </div>
  )
}

export default TextError