import React, { useState, useEffect } from "react";
import "./AllAccounts.css";
import * as API from "../../../util/url";
import PageSize from "../../../Common/PageSize";
import ProjectPagination from "../../../Common/Pagination/ProjectPagination";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const AllAccounts = () => {
  const [accountData, setAccountData] = useState([]);
  const navigate = useNavigate();

  const [pageSize, setPageSize] = useState("10");
  const [pageCount, setPageCount] = useState(0);
  const [dataCount, setDataCount] = useState(0);
  const getAllAccount = async (pageNumber = 1, keyword = "", status, order) => {
    try {
      let response = await API.allAccounts({
        pageNumber: pageNumber,
        size: pageSize,
      });
      // console.log(response,'rvm');
      setAccountData(response.data.map.users);
      setPageCount(response.data.map.size / pageSize);
      setDataCount(response.data.map.size);
    } catch (error) {
      console.log(error);
    }
  };

  const handlePageSize = (data) => setPageSize(data);
  const loginUser = async (data) => {
    console.log(data);
    try {
      let response = await API.loginUser(data);

      localStorage.setItem(
        "bestUrl",
        response.data.map.accesstoken.accessToken
      );
      localStorage.setItem("userName", response.data.map.name);
      localStorage.setItem("email", response.data.map.email);
      localStorage.setItem("uId", response.data.map.uId);
      navigate("/");
    } catch (error) {
      console.log(error);
    }
  };

  const spamUserHandler = async (data) => {
    console.log(data);
    try {
      let response = await API.spamUser(data);
    } catch (error) {
      console.log(error);
    }
  };

  const googleAuth = async (data) => {
    console.log(data);
    try {
      let response = await API.googleAuth(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllAccount();
  }, [pageSize]);
  const keywordFilter = (searchData) => {
    getAllAccount(1, searchData);
  };

  return (
    <div className="admin-sidenav-options">
      <div className="page_tittle">
        <h3 className="px-3">All Accounts</h3>
      </div>
      <div
        className="d-flex"
        style={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <div className="showing">
          <h6>Showing {pageSize} items</h6>
        </div>
        <div className="searchcol px-3">
          <input
            className="searchinput"
            type="text"
            placeholder="Search.. "
            onChange={(e) => keywordFilter(e.target.value)}
          />
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-12">
          <div className="table-container container">
            <table className="table tablediv table-hover acutiontble table-striped">
              <thead className="headertable">
                <tr>
                  <th scope="col"> Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Phone Number</th>
                  <th scope="col">Country</th>
                  <th scope="col"> State</th>
                  <th scope="col"> City</th>
                  <th scope="col"> ZipCode</th>
                  <th scope="col"> Verify</th>
                  <th scope="col"> Google Authentication</th>
                  <th scope="col"> Creation Date</th>
                  <th scope="col"> Last Login</th>
                  <th scope="col"> Login</th>
                </tr>
              </thead>
              <tbody>
                {accountData.map((data) => {
                  return (
                    <tr>
                      <td>{data.username}</td>
                      <td>{data.email}</td>
                      <td>{data.phoneNumber}</td>
                      <td>{data.country}</td>
                      <td>{data.state}</td>
                      <td>{data.city}</td>
                      <td>{data.zipCode}</td>
                      <td>
                        <label className="switch">
                          <input
                            type="checkbox"
                            defaultChecked={data.verified}
                            onClick={(e) =>
                              spamUserHandler({
                                userId: data.userId,
                                status: e.target.checked ? "verify" : "spam",
                              })
                            }
                          />
                          <span className="slider round"></span>
                        </label>
                      </td>
                      <td>
                        <label className="switch">
                          <input
                            type="checkbox"
                            defaultChecked={data.verified}
                            onClick={(e) =>
                              googleAuth({
                                userId: data.userId,
                                status: e.target.checked ? "on" : "off",
                              })
                            }
                          />
                          <span className="slider round"></span>
                        </label>
                      </td>
                      <td>{moment(data.creationDate).format("ll")}</td>
                      <td>{moment(data.lastLogin).format("ll")}</td>

                      <td
                        style={{ cursor: "pointer" }}
                        onClick={() => loginUser({ id: data.userId })}
                      >
                        {" "}
                        <div className="button-update">Login</div>{" "}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div style={{ position: "relative", height: "100px" }}>
        {dataCount > pageSize && (
          <ProjectPagination
            handlePageClick={(e) => getAllAccount(e.selected + 1)}
            pageCount={pageCount}
          />
        )}

        <div className="pageSizeContainer">
          <PageSize
            title={`${pageSize} items`}
            handleData={(e) => handlePageSize(e)}
          />
        </div>
      </div>
    </div>
  );
};

export default AllAccounts;
