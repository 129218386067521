import React, { useState, useEffect } from "react";
import * as API from "../../util/url";
import PageSize from "../../Common/PageSize";
import ProjectPagination from "../../Common/Pagination/ProjectPagination";
import TableComponent from "../../Common/TableComponent/TableComponent";
import Moment from "moment";
import { GoInbox } from "react-icons/go";
import { useNavigate } from "react-router-dom";

const LandingOffer = () => {
  const navigate = useNavigate();
  const [makeOffer, setMakeOffer] = useState([]);
  const [pageSize, setPageSize] = useState("10");
  const [pageCount, setPageCount] = useState(0);
  const [dataCount, setDataCount] = useState(0);

  const tableColumns = [
    {
      accessorKey: "productName",
      header: "Product Name",
      cell: (props) => <div>{props.getValue()}</div>,
    },
    {
      accessorKey: "type",
      header: "Product Type",
      cell: (props) => <div>{props.getValue()}</div>,
    },
    {
      accessorKey: "offerType",
      header: "offer Type",
      cell: (props) => <div>{props.getValue()}</div>,
    },
    {
      accessorKey: "message",
      header: "Recent Message",
      cell: (props) => <div>{props.getValue()}</div>,
    },
    {
      accessorKey: "role",
      header: "Role",
      cell: (props) => <div>{props.getValue()}</div>,
    },
    {
      accessorKey: "timeRemaining",
      header: "Time Left",
      cell: (props) => <div>{props.getValue()}</div>,
    },
    {
      accessorKey: "endDate",
      header: "End Date",
      cell: (props) => (
        <div>{Moment(props.getValue()).format("DD-MM-YYYY")}</div>
      ),
    },
    {
      accessorKey: "price",
      header: "Buynow",
      cell: (props) => <div>${props.getValue()}</div>,
    },
    {
      accessorKey: "makeId",
      header: "View",
      cell: (props) => (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => viewMessageHandler(props.getValue())}
        >
          <GoInbox />
        </div>
      ),
    },
  ];
  const handlePageSize = (data) => setPageSize(data);

  const viewMessageHandler = (data) => {
    navigate("/dashboard/transaction-message", { state: data });
  };

  const keywordFilter = (searchData) => {
    OfferDomains(1, searchData);
  };
  const OfferDomains = async (pageNumber = 1, keyword = "", status, order) => {
    try {
      let response = await API.getMakeOffers({
        pageNumber: pageNumber,
        size: pageSize,
        keyword: keyword,
        // listType: "string",
        // type: "string",
        // sold: true,
        // currency: "string",
        // sortBy: "string",
        // sortWay: "string",
        // productType: [
        //   "string"
        // ]
      });
      console.log(response, "rsp");

      setMakeOffer(response.data.map.makeOffers);
      setPageCount(response.data.map.size / pageSize);
      setDataCount(response.data.map.size);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    OfferDomains();
  }, [pageSize]);
  return (
    <div
      style={{
        backgroundColor: "white",
        borderRadius: "20px",
        boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
      }}
    >
      <div className="row">
        <div className="col-lg-12 col-md-12 col-12 auctiontable">
          <div className="page_tittle">
            <h3>Offers Details</h3>
          </div>
          <div
            className="d-flex"
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <div className="showing">
              <h6>Showing {pageSize} items</h6>
            </div>
            <div className="searchcol px-3">
              <input
                className="searchinput"
                type="text"
                placeholder="Search.. "
                onChange={(e) => keywordFilter(e.target.value)}
              />
            </div>
          </div>
          <div className="container table-container mt-3">
            {/* Tanstack Table  */}

            <TableComponent
              data={makeOffer}
              columns={tableColumns}
              // sorting={sorting}
              // setSorting={setSorting}
              // filter={(e) => tableFilter(e)}
            />
            <div style={{ position: "relative", height: "50px" }}>
              {dataCount > pageSize && (
                <ProjectPagination
                  handlePageClick={(e) => OfferDomains(e.selected + 1)}
                  pageCount={pageCount}
                />
              )}

              <div className="pageSizeContainer">
                <PageSize
                  title={`${pageSize} items`}
                  handleData={(e) => handlePageSize(e)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingOffer;
