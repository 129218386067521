import React from "react";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";

import "./StatusModal.css";
const StatusModal = (props) => {
  const navigate = useNavigate();
  const { show, status, message, url } = props.show;
  // console.log(show, status, message, url, 'pro');

  const closeModal = () => {
    if (url !== "") {
      navigate(url);
      props.onHide();
    }
    props.onHide();
  };

  return (
    <Modal
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      id="success-body"
    >
      <Modal.Body>
        {status === "success" && (
          <div className={"modal__icon modal__icon--success"}>
            <span data-feather="check">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-check"
              >
                <polyline points="20 6 9 17 4 12"></polyline>
              </svg>
            </span>
          </div>
        )}
        {status === "error" && (
          <div className={"modal__icon modal__icon--error"}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-x"
            >
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </div>
        )}

        <div className="statusText mt-5">
          {status === "success" && <h3 className="modal__title">Success</h3>}
          {status === "error" && <h3 className="modal__title">Error</h3>}

          <p className="modal__text text-center mt-2">{message}</p>
        </div>
      </Modal.Body>
      {status === "success" && (
        <button
          className={"modalBodyButton modal__icon--success pb-2"}
          onClick={closeModal}
        >
          Close
        </button>
      )}
      {status === "error" && (
        <button
          className={"modalBodyButton modal__icon--error pb-2"}
          onClick={closeModal}
        >
          Close
        </button>
      )}
    </Modal>
  );
};

export default StatusModal;
