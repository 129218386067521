import React, { useState, useEffect } from "react";
import * as API from "../../../util/url";
import PageSize from "../../../Common/PageSize";
import ProjectPagination from "../../../Common/Pagination/ProjectPagination";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const EmailContent = () => {
  const [accountData, setAccountData] = useState([]);
  const navigate = useNavigate();

  const [pageSize, setPageSize] = useState("10");
  const [pageCount, setPageCount] = useState(0);
  const [dataCount, setDataCount] = useState(0);
  const getAllAccount = async (pageNumber = 1, keyword = "", status, order) => {
    try {
      let response = await API.getEmailData();
      console.log(response, "rvm");
      //   setAccountData(response.data.map.users);
      setPageCount(response.data.map.size / pageSize);
      setDataCount(response.data.map.size);
    } catch (error) {
      console.log(error);
    }
  };

  const handlePageSize = (data) => setPageSize(data);

  useEffect(() => {
    getAllAccount();
  }, []);
  const keywordFilter = (searchData) => {
    getAllAccount(1, searchData);
  };

  return (
    <div className="admin-sidenav-options">
      <div className="page_tittle">
        <h3 className="px-3">Email</h3>
      </div>
      <div
        className="d-flex"
        style={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <div className="showing">
          <h6>Showing {pageSize} items</h6>
        </div>
        <div className="searchcol px-3">
          <input
            className="searchinput"
            type="text"
            placeholder="Search.. "
            onChange={(e) => keywordFilter(e.target.value)}
          />
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-12">
          <div className="table-container container">
            <table className="table tablediv table-hover acutiontble table-striped">
              <thead className="headertable">
                <tr>
                  <th scope="col"> Sr.No</th>
                  <th scope="col">Subject</th>
                  <th scope="col">Content</th>
                  <th scope="col">Edit</th>
                </tr>
              </thead>
              <tbody>
                {/* {accountData.map((data)=>{
                    return <tr>
                        <td>{data.username}</td>
                        <td>{data.email}</td>
                        <td>{data.phoneNumber}</td>
                        <td>{data.country}</td>
                        <td>{data.state}</td>
                        <td>{data.city}</td>
                        <td>{data.zipCode}</td>
  
                    </tr>
                })} */}
              </tbody>
            </table>
            <p>No Record Found.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailContent;
