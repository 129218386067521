import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Dropdown from "react-bootstrap/Dropdown";
import { FiUser } from "react-icons/fi";

const AdminNavbar = () => {
  // const { userName, accessToken } = useSelector((state) => (state.auth));
  const navigate = useNavigate();
  const bestUrl = localStorage.getItem("bestUrl");
  const userName = localStorage.getItem("userName");

  const logoutHandler = () => {
    localStorage.removeItem("bestUrl");
    localStorage.removeItem("grant");

    navigate("/signin");
  };

  return (
    <div>
      <nav
        className="navbar navs navbar-expand-lg"
        style={{
          paddingTop: "0.5rem",
          paddingBottom: "0.5rem",
          backgroundColor: "#5983e8",
          // boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
        }}
      >
        <div className="container">
          <NavLink
            to="/"
            className="navbar-brand"
            style={{ fontSize: "30px", fontWeight: " 700 ", color: "white" }}
          >
            DNGH
          </NavLink>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div
            className="collapse navbar-collapse navbtn "
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0 text-center">
              <li className="nav-item ">
                <NavLink
                  className="nav-link me-5 admin-navbar-options"
                  to="/marketplace"
                >
                  Marketplace
                </NavLink>
              </li>
            </ul>

            {bestUrl !== null ? (
              <div className="d-flex" style={{ alignItems: "baseline" }}>
                <>
                  <Dropdown>
                    <Dropdown.Toggle
                      style={{
                        background: "transparent",
                        border: "none",
                        fontSize: "25px",
                        color: "white",
                      }}
                    >
                      <FiUser />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-menu dropdown-menu-end animate slideIn mt-4">
                      <div
                        className="px-2 py-1 d-flex"
                        style={{ fontSize: "14px" }}
                      >
                        Welcome{" "}
                        <span className="mx-2" style={{ fontWeight: "bold" }}>
                          {" "}
                          {userName}
                        </span>
                      </div>
                      <Dropdown.Divider />
                      <div className="px-2 py-1 stripe">
                        <NavLink
                          className=" me-5"
                          style={{ color: "black" }}
                          to="/dashboard/profile"
                        >
                          Profile
                        </NavLink>
                      </div>

                      <div className="px-2 py-1 stripe">
                        <div
                          className=" me-5"
                          style={{ color: "black", cursor: "pointer" }}
                          onClick={logoutHandler}
                        >
                          Logout
                        </div>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </>
              </div>
            ) : (
              <div className="button">
                <NavLink to={"/signin"} className="sign">
                  Login
                </NavLink>
              </div>
            )}
          </div>
        </div>
      </nav>
      {/* <hr style={{ margin: "0px" }} /> */}
    </div>

    // </div>
  );
};

export default AdminNavbar;
