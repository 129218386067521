import React, { useEffect } from 'react'
import './TableComponent.css'
import { useReactTable, getCoreRowModel, flexRender, columnResizeMode , SortingState} from '@tanstack/react-table'
import Table from 'react-bootstrap/Table';
import { IoIosArrowRoundUp } from "react-icons/io";
import { IoIosArrowRoundDown } from "react-icons/io";
const TableComponent = ({ data, columns ,sorting, setSorting, filter}) => {
    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        // columnResizeMode: "onChange",
        // state: {sorting},
        //   manualSorting: true,
        //   onSortingChange: setSorting,
        
    });

    // useEffect(()=>filter(sorting),[sorting])
    return (
        <div>
            <Table hover className='tablediv'>
                <thead className='tableheading'>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <tr key={headerGroup.id} className='adminheader'>
                            {headerGroup.headers.map((header) => (
                                <th className='tableHead' key={header.id}>
                                    {header.column.columnDef.header}
                            
                                    
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody>
                    {table.getRowModel().rows.map((row) => (
                        <tr key={row.id}>
                            {row._getAllVisibleCells().map((cell) => (
                                <td key={cell.id}>
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    )
}

export default TableComponent
