import React from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Home from "./Pages/Home/Home";
import LandingPreview from "./Pages/LandingPreview/LandingPreview";
import MarketPlace from "./Pages/Marketplace/MarketPlace";
import DashBoard from "./Pages/DashBoard";
import Profile from "./Pages/Profile/Profile";
import Auction from "./Pages/Listing/Auction";
import WebsiteClassified from "./Pages/Listing/WebsiteClassified";
import Draft from "./Pages/Listing/Draft";
import TransferCenter from "./Pages/Listing/TransferCenter";
import WebsiteLayout from "./Common/WebsiteLayout";
import WatchList from "./Pages/Listing/WatchList";
import Payment from "./Pages/Payment";
import Bids from "./Pages/Bids";
import DomainSold from "./Pages/DomainSold";
import BlogPage from "./Pages/BlogPage";
import DomainPortfolio from "./Pages/Listing/DomainPortfolio";
import LandingOffer from "./Pages/LandingOffer/LandingOffer";
import Login from "./Pages/Login/Login";
import SignUp from "./Pages/Login/SignUp";
import Listing from "./Pages/DomainListing/Listing/Listing";
import DomainAuction from "./Pages/DomainListing/DomainAuction/DomainAuction";
import MakeOffer from "./Pages/DomainListing/MakeOffer/MakeOffer";
import AuctionPreview from "./Pages/AuctionPreview/AuctionPreview";
import BiddingHistory from "./Pages/BiddingHistory/BiddingHistory";
import TransactionMessage from "./Pages/TransactionMessage/TransactionMessage";
import AdminDashboard from "./Pages/Admin/AdminDashboard/AdminDashboard";
import AdminLayout from "./Common/AdminLayout";
import AllAccounts from "./Pages/Admin/AllAccounts/AllAccounts";
import AdminListing from "./Pages/Admin/AdminAuctionListing/AdminAuctionListing";
import AdminDomainPortfolio from "./Pages/Admin/AdminDomainPortfolio/AdminDomainPortfolio";
import AllBidding from "./Pages/Admin/AllBidding/AllBidding";
import AllDomains from "./Pages/Admin/AllDomains/AllDomains";
import AdminFilter from "./Pages/Admin/AdminFilter/AdminFilter";
import AdminMakeOffer from "./Pages/Admin/AdminMakeOffer/AdminMakeOffer";
import AdminMakeOfferData from "./Pages/Admin/AdminMakeOfferData/AdminMakeOfferData";
import EmailContent from "./Pages/Admin/EmailContent/EmailContent";
import PaymentHistory from "./Pages/Admin/PaymentHistory/PaymentHistory";
import AdminGetMakeOffer from "./Pages/Admin/AdminGetMakeOffer/AdminGetMakeOffer";
import MakeOfferMessage from "./Pages/Admin/MakeOfferMessage/MakeOfferMessage";
import Notification from "./Pages/Admin/Notification/Notification";
import Admin_user from "./Pages/Admin/Admin_user/Admin_user";
import Watchers from "./Pages/Admin/Watchers/Watchers";
import Admin_Roles from "./Pages/Admin/Admin_Roles/Admin_Roles";
import Admin_UserRole from "./Pages/Admin/Admin_UserRole/Admin_UserRole";
import Admin_ContactUs from "./Pages/Admin/Admin_ContactUs/Admin_ContactUs";
import Domain_ReportData from "./Pages/Admin/Domain_ReportData/Domain_ReportData";
import DomainReportStatus from "./Pages/Admin/DomainReportStatus/DomainReportStatus";
import Admin_EmailData from "./Pages/Admin/Admin_EmailData/Admin_EmailData";
import AdminPatternData from "./Pages/Admin/AdminPatternData/AdminPatternData";
import PaymentMessageData from "./Pages/Admin/PaymentMessageData/PaymentMessageData";
import PaymentMainChatData from "./Pages/Admin/PaymentMainChatData/PaymentMainChatData";
import PaymentTrackData from "./Pages/Admin/PaymentTrackData/PaymentTrackData";
import SolonaPay from "./Pages/SolonaPay/SolonaPay";
import TransferCenterMessage from "./Pages/TransferCenterMessage/TransferCenterMessage";
import PageNotFound from "./Pages/PageNotFound/PageNotFound";
import PrivacyPolicy from "./Pages/PrivacyPolicy/PrivacyPolicy";
import TermsofService from "./Pages/TermsOfServices/TermsofService";
import BuyerGuide from "./Pages/BuyerGuidelines/BuyerGuide";
import SellerGuide from "./Pages/SellerGuidelines/SellerGuide";
import Faq from "./Pages/FAQ/Faq";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/signin" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/listing" element={<Listing />} />
        <Route path="/domain-auction" element={<DomainAuction />} />
        <Route path="/make-offer" element={<MakeOffer />} />
        <Route path="/marketplace" element={<MarketPlace />} />
        <Route path="/auction-preview" element={<AuctionPreview />} />
        <Route path="/bidding-history" element={<BiddingHistory />} />
        <Route path="/blog" element={<BlogPage />} />
        <Route path="/solona-pay" element={<SolonaPay />} />
        <Route path="/offer-preview" element={<LandingPreview />} />
        {/* <Route path="*" element={<Home />} /> */}
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-service" element={<TermsofService />} />
        <Route path="/buyer-guidelines" element={<BuyerGuide />} />
        <Route path="/seller-guidelines" element={<SellerGuide />} />{" "}
        <Route path="/faq" element={<Faq />} />
        <Route path="*" element={<PageNotFound />} />
        <Route path="/dashboard" element={<WebsiteLayout />}>
          <Route path="/dashboard" element={<DashBoard />} />
          <Route path="/dashboard/profile" element={<Profile />} />
          <Route path="/dashboard/auction" element={<Auction />} />
          <Route path="/dashboard/draft" element={<Draft />} />
          <Route
            path="/dashboard/transfercenter"
            element={<TransferCenter />}
          />
          <Route path="/dashboard/watchlist" element={<WatchList />} />
          <Route path="/dashboard/payment" element={<Payment />} />
          <Route path="/dashboard/bids" element={<Bids />} />
          <Route path="/dashboard/domain-sold" element={<DomainSold />} />
          <Route
            path="/dashboard/domain-portfolio"
            element={<DomainPortfolio />}
          />
          <Route path="/dashboard/offer" element={<LandingOffer />} />
          <Route
            path="/dashboard/transaction-message"
            element={<TransactionMessage />}
          />
          <Route
            path="/dashboard/transfer-center-message"
            element={<TransferCenterMessage />}
          />
        </Route>
        <Route path="/power/dashboard" element={<AdminLayout />}>
          <Route path="/power/dashboard" element={<AdminDashboard />} />
          <Route
            path="/power/dashboard/allaccounts"
            element={<AllAccounts />}
          />
          <Route
            path="/power/dashboard/all-auction-listings"
            element={<AdminListing />}
          />
          <Route
            path="/power/dashboard/all-domain-portfolio"
            element={<AdminDomainPortfolio />}
            s
          />
          <Route path="/power/dashboard/all-bidding" element={<AllBidding />} />
          <Route path="/power/dashboard/all-domains" element={<AllDomains />} />
          <Route path="/power/dashboard/all-filter" element={<AdminFilter />} />
          <Route
            path="/power/dashboard/all-make-offer-message"
            element={<AdminMakeOffer />}
          />
          <Route
            path="/power/dashboard/all-make-offer-data"
            element={<AdminMakeOfferData />}
          />
          <Route
            path="/power/dashboard/payment-history"
            element={<PaymentHistory />}
          />
          <Route
            path="/power/dashboard/all-make-offer-data"
            element={<AdminMakeOfferData />}
          />
          <Route
            path="/power/dashboard/notification"
            element={<Notification />}
          />
          <Route path="/power/dashboard/admin-user" element={<Admin_user />} />
          <Route
            path="/power/dashboard/admin-Watchers"
            element={<Watchers />}
          />

          <Route
            path="/power/dashboard/admin-get-make-offer"
            element={<AdminGetMakeOffer />}
          />
          <Route
            path="/power/dashboard/admin-make-offer-message"
            element={<MakeOfferMessage />}
          />
          <Route
            path="/power/dashboard/admin-roles"
            element={<Admin_Roles />}
          />
          <Route
            path="/power/dashboard/admin-userroles"
            element={<Admin_UserRole />}
          />
          <Route
            path="/power/dashboard/admin-contact-us"
            element={<Admin_ContactUs />}
          />
          <Route
            path="/power/dashboard/admin-domain-report-data"
            element={<Domain_ReportData />}
          />
          <Route
            path="/power/dashboard/admin-domain-report-status"
            element={<DomainReportStatus />}
          />
          <Route
            path="/power/dashboard/admin-EmailData"
            element={<Admin_EmailData />}
          />
          <Route
            path="/power/dashboard/admin-PatternData"
            element={<AdminPatternData />}
          />
          <Route
            path="/power/dashboard/payment-message"
            element={<PaymentMessageData />}
          />
          <Route
            path="/power/dashboard/payment-main-chat"
            element={<PaymentMainChatData />}
          />
          <Route
            path="/power/dashboard/payment-track"
            element={<PaymentTrackData />}
          />

          <Route
            path="/power/dashboard/email-content"
            element={<EmailContent />}
          />
        </Route>
      </Routes>
      <ToastContainer />
    </div>
  );
}

export default App;
