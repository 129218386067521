import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { CgCloseO } from "react-icons/cg";
import ApiCaller from "../../../util/ApiCaller";
import * as API from "../../../util/url";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
const AuctionBidding = (props) => {
  const [price, setPrice] = useState(0);
  const [apiInfo, setApiInfo] = useState({
    path: "",
    redirectUrl: "",
  });

  const onChangeHandler = (price) => {
    setPrice(price);
    if (price < props.domainData.startingPrice) {
      document.getElementById("PriceError").style.display = "block";
    } else {
      document.getElementById("PriceError").style.display = "none";
    }
  };
  //   const {domainName} = props.makeOfferModalData;

  const placedBid = async () => {
    setApiInfo({
      path: () =>
        API.bidPlaced({
          id: props.domainData.productId,
          price: price,
        }),
      redirectUrl: "/bidding-history",
    });
  };
  return (
    <div>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="modal-content border-0">
            <div className="modal-header border-0 p-0 d-block text-end">
              <CgCloseO
                className="mx-2 mt-1"
                style={{ fontSize: "22px", cursor: "pointer" }}
                onClick={() => props.onHide()}
              />
            </div>
            <div className="modal-body p-0">
              <div className="card border-0">
                <div
                  className="card-header bg-white"
                  style={{ padding: "0 1.5rem" }}
                >
                  <h3 style={{ fontSize: "16px", fontWeight: "700" }}>
                    This Domain is for auction
                  </h3>
                </div>
                <div className="card body p-4 pt-0 border-0">
                  <div className="my-3 fw-700" style={{ fontSize: "18px" }}>
                    {props.domainData.productName}
                  </div>
                  <div
                    className="d-flex"
                    style={{ justifyContent: "space-between" }}
                  >
                    <div>
                      <div style={{ color: "#0245a3" }}>
                        {props.domainData.totalBids} Bid
                      </div>
                      <p>{props.domainData.timeRemaining}</p>
                    </div>
                    <div>
                      <div>Current Price</div>
                      <p
                        className="fw-700"
                        //  style={{ color: "#01c065" }}
                      >
                        $ {props.domainData.currentPrice}
                      </p>
                    </div>
                  </div>
                  <div className="mt-3">
                    <p style={{ color: "#6c757d" }}>Reserve met</p>

                    <div className="makeofferinput-black">
                      <InputGroup className="mb-2 ">
                        <Form.Control
                          placeholder="$0"
                          aria-label="Recipient's username"
                          aria-describedby="basic-addon2"
                          onChange={(e) => onChangeHandler(e.target.value)}
                          type="number"
                          className=""
                        />

                        <InputGroup.Text
                          id="basic-addon2"
                          onClick={placedBid}
                          style={{
                            // background:
                            //   "linear-gradient(90deg,#c766ef,#7928d2 51.04%,#2b0c52)",
                            color: "white",
                            cursor: "pointer",
                          }}
                        >
                          Place Bid
                        </InputGroup.Text>
                      </InputGroup>
                      <div
                        id="PriceError"
                        className="errorMsg mt-1 priceError"
                        style={{
                          color: "red",
                          textAlign: "left",
                          fontSize: "13px",
                        }}
                      >
                        * Price must be greater than the Dollar
                        {props.domainData.startingPrice}
                      </div>
                    </div>
                  </div>
                  <div className="text-center  mt-2">
                    Questions? Talk to a domain expert:{" "}
                  </div>
                  <div className="text-center" style={{ color: "blue" }}>
                    {" "}
                    +1 (425) 880-0977
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
            <ApiCaller apiData={apiInfo} />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AuctionBidding;
