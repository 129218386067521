import React from "react";
import "./Home.css";
import LandingBanner from "./LandingBanner";
import FeatureListing from "./FeatureListing";
import Listing from "./Listing";
import SellBuy from "./SellBuy";
import KeyFeature from "./KeyFeature";
import HomeProcess from "./HomeProcess";
import HomeBlog from "./HomeBlog";
import Footer from "../../Common/Footer";

const Home = () => {
  return (
    <div style={{ overflow: "hidden" }}>
      <LandingBanner />
      <FeatureListing />
      <Listing />
      <KeyFeature />
      <SellBuy />
      <HomeProcess />
      <HomeBlog />
      <Footer />
    </div>
  );
};

export default Home;
