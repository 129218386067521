import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Dropdown from "react-bootstrap/Dropdown";
import { FiUser } from "react-icons/fi";
import { IoMdNotificationsOutline } from "react-icons/io";
import * as API from "../util/url";
import moment from "moment";
import parse from "html-react-parser";

const LandingNavbar = () => {
  // const { userName, accessToken } = useSelector((state) => (state.auth));

  const [count, setCount] = useState(0);
  const [message, setMessage] = useState([]);
  const navigate = useNavigate();
  const bestUrl = localStorage.getItem("bestUrl");
  const userName = localStorage.getItem("userName");

  const logoutHandler = () => {
    localStorage.removeItem("bestUrl");
    localStorage.removeItem("grant");
    navigate("/signin");
  };

  const getNotification = async () => {
    try {
      let response = await API.getUserNotification();
      console.log(response, "rsp");
      setCount(response.data.map.unread);
      setMessage(response.data.map.notification);
    } catch (error) {
      console.log(error);
    }
  };
  const updateNotificationHandler = async (id) => {
    try {
      let response = await API.updateNotification({
        id: [id],
      });
      setCount(response.data.map.unread);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getNotification();
  }, []);
  return (
    <div>
      <nav
        className="navbar navs navbar-expand-lg container-fluid"
        style={{
          paddingTop: "0.8rem",
          paddingBottom: "0.5rem",
          // borderBottom: "1px solid #c2c6cb",
          // backgroundColor: "aliceblue",
          // boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
        }}
      >
        <div className="container">
          <NavLink
            to="/"
            className="navbar-brand"
            // style={{ color: "#fff" }}
            style={{ color: "Black", fontSize: "30px", fontWeight: "700" }}
          >
            <div className="navbar-company-logo"></div>
          </NavLink>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div
            className="collapse navbar-collapse navbtn "
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0 text-center">
              <li className="nav-item ">
                <NavLink className="nav-link" to="/marketplace">
                  Marketplace
                </NavLink>
              </li>
              <li className="nav-item ">
                <NavLink to={"/make-offer"} className="nav-link me-5">
                  List Domains
                </NavLink>
              </li>
            </ul>

            {bestUrl !== null ? (
              <div className="d-lg-flex" style={{ alignItems: "baseline" }}>
                <>
                  <Dropdown>
                    <Dropdown.Toggle
                      style={{
                        background: "transparent",
                        border: "none",
                        fontSize: "25px",
                        padding: "5px 10px",
                        marginRight: "25px",
                        color: "black",
                      }}
                    >
                      <div style={{ position: "relative" }}>
                        <IoMdNotificationsOutline
                          style={{ fontSize: "24px" }}
                        />
                        <span className="notifyCount">{count}</span>
                      </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      className="dropdown-menu dropdown-menu-end animate slideIn mt-4 scrollable-element"
                      style={{
                        height: "auto",
                        overflowY: "scroll",
                        width: "10rem",
                        boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                      }}
                    >
                      {message.length === 0 ? (
                        <div className="px-2 py-1 stripe">
                          <NavLink
                            className="  "
                            style={{ color: "black" }}
                            to="/dashboard"
                          >
                            no notification
                          </NavLink>
                        </div>
                      ) : (
                        <>
                          {message.map((data) => {
                            return (
                              <div
                                className="px-2 py-1 stripe m-2"
                                onClick={() => {
                                  navigate(data.href, { state: data.reqId });
                                  updateNotificationHandler(data.id);
                                }}
                                style={{
                                  background: "#f3f7f9",
                                  width: "260px",
                                }}
                              >
                                <div
                                  className="  "
                                  style={{ color: "black", fontSize: "14px" }}
                                >
                                  {parse(data.message)}
                                  <br />
                                  {moment(data.date).format("ll")}
                                </div>
                              </div>
                            );
                          })}
                        </>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>

                  <Dropdown>
                    <Dropdown.Toggle
                      style={{
                        background: "transparent",
                        border: "none",
                        fontSize: "25px",
                        color: "black",
                      }}
                    >
                      <FiUser className="fiuser-profile" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      className="dropdown-menu dropdown-menu-end animate slideIn mt-4"
                      style={{
                        boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                      }}
                    >
                      <div
                        className="px-2 py-1"
                        style={{ fontSize: "15px", width: "125px" }}
                      >
                        Welcome{" "}
                        <span
                          style={{ fontWeight: "bold", overflow: "scroll" }}
                        >
                          {" "}
                          {userName}
                        </span>
                      </div>
                      <Dropdown.Divider />
                      <div className="px-2 py-1 stripe">
                        <NavLink
                          className="  "
                          style={{ color: "black" }}
                          to="/dashboard"
                        >
                          Dashboard
                        </NavLink>
                      </div>
                      <div className="px-2 py-1 stripe">
                        <NavLink
                          className=" "
                          style={{ color: "black" }}
                          to="/dashboard/profile"
                        >
                          Profile
                        </NavLink>
                      </div>

                      <div className="px-2 py-1 stripe">
                        <div
                          className=" "
                          style={{ cursor: "pointer", color: "black" }}
                          onClick={logoutHandler}
                        >
                          Logout
                        </div>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>

                  {/* <div className="button create-listing-btn ">
                    <NavLink to="/listing" className="sign">
                      Create Listing
                    </NavLink>
                  </div> */}
                </>
              </div>
            ) : (
              <div className="button">
                <NavLink to={"/signin"} className="sign">
                  Login
                </NavLink>
              </div>
            )}
          </div>
        </div>
      </nav>
    </div>

    // </div>
  );
};

export default LandingNavbar;
