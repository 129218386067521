import React from 'react'
import ReactPaginate from 'react-paginate';
import "./ProjectPagination.css";
const ProjectPagination = ({handlePageClick,pageCount}) => {
  return (
    <div className='d-flex' style={{justifyContent:'center', alignItems:'center'}}>
    <div className='paginationComponent'>
        <ReactPaginate
        nextLabel=" Next >  "
        onPageChange={handlePageClick}
        pageRangeDisplayed={2}
        marginPagesDisplayed={2}
        pageCount={pageCount}
        previousLabel=" < Prev"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination"
        activeClassName="active"
        renderOnZeroPageCount={null}
      />
    </div>
    </div>
  )
}

export default ProjectPagination