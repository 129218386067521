import { useEffect } from "react";
import Footer from "../../Common/Footer";
import LandingNavbar from "../../Common/LandingNavbar";
import "./Faq.css";

const Faq = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <LandingNavbar />
      <div className="faq-page-container">
        <div className="container">
          <div className="faq-container">
            <h3>FAQ</h3>
            <div className="accordion" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    1. How do I register an account on DNGH?
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse show"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    To register, simply visit the DNGH homepage, click on 'Sign
                    Up', and fill in the required fields with your information.
                    Once submitted, you'll receive an email to verify your
                    account.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    2. What are the payment methods accepted on DNGH?{" "}
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    DNGH accepts various payment methods including credit cards
                    and certain cryptocurrencies. We ensure all transactions are
                    secure and comply with financial regulations.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    3. How do I list a domain for sale?{" "}
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    After logging into your account, navigate to the 'Sell
                    Domain' section, enter the details of your domain, including
                    a fair price and description, and submit it for listing.
                    Make sure your description accurately reflects the domain's
                    features and benefits.
                  </div>
                </div>
              </div>{" "}
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    4. What should I do if I encounter a problem with a
                    transaction?
                  </button>
                </h2>
                <div
                  id="collapseFour"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    If you face any issues during a transaction, contact our
                    support team immediately via [support@svad.com]. We provide
                    a dispute resolution process to handle such cases fairly and
                    efficiently.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFive"
                    aria-expanded="false"
                    aria-controls="collapseFive"
                  >
                    5. How can I ensure my account is secure?
                  </button>
                </h2>
                <div
                  id="collapseFive"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    To secure your account, use a strong password, enable
                    two-factor authentication if available, and never share your
                    login details with anyone. Regularly updating your password
                    and monitoring your account activity can also help maintain
                    security.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>{" "}
      <Footer />
    </div>
  );
};

export default Faq;
