import React, { useState } from "react";
import { QRCode } from "react-qrcode-logo";
import "./SolonaPay.css";
import CopyToClipboard from "../../Common/CopyToClipboard/CopyToClipboard";
import Modal from "react-bootstrap/Modal";
import * as API from "../../util/url";
import StatusModal from "../../Common/Modal/StatusModal/StatusModal";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

const SolonaPay = (props) => {
  console.log(props, "prp");
  

  const userId = localStorage.getItem("uId")
  console.log(localStorage.getItem("uId"))

  const renderTime = ({ remainingTime }) => {
    if (remainingTime === 0) {
      return <div className="timer">Too late...</div>;
    }

    return (
      <div className="timer">
        <div className="text">Remaining</div>
        <div className="value">{remainingTime}</div>
        <div className="text">seconds</div>
      </div>
    );
  };

  const initalStatus = {
    show: false,
    status: "",
    message: "",
    url: "",
  };

  const [statusModal, setStatusModal] = useState(initalStatus);

  const copyToClipboard = (data) => navigator.clipboard.writeText(data);
  const walletAddress = "0x8B9EA98f3C74dd9869DA14DAbB9b67dFd2514C07";
  // console.log(props, 'prp');

  const [modalData, setModalData] = useState({});
  const [messageData, setMessageData] = useState("");

  const apiTimerHandler = (time) => {
    time % 10 === 0 && verifyCodeHandler(time);
  };

  const verifyCodeHandler = async (time) => {
    try {
      let response = await API.verifyQrCode({
        reference: props?.qrCode?.refer,
        userId,
        paymentId:props?.paymentId

      });
      // console.log(response.data.response,'rst');
      setModalData(response.data.map);
      if (response.data.response.success === true) {
        setStatusModal({
          show: true,
          status: "success",
          message:
            "Your transaction has beeen Verified Your domain Will be transferd in next 48 hours",
          url: "/dashboard/payment",
        });

        props.onHide();
      }

      if (time === 0) {
        // alert('Time over')
        setStatusModal({
          show: true,
          status: "error",
          message: "Transaction time limit has been reached.",
          url: "",
        });
        props.onHide();
      }
    } catch (error) {
      if (time === 0) {
        // alert('Time over')
        setStatusModal({
          show: true,
          status: "error",
          message: "Transaction time limit has been reached.",
          url: "",
        });
        props.onHide();
      }
    }
  };

  return (
    <div>
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Solana Pay
          </Modal.Title>
          {/* <div onClick={() => props.onHide()} style={{ cursor: "pointer" }}>
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M405 136.798L375.202 107 256 226.202 136.798 107 107 136.798 226.202 256 107 375.202 136.798 405 256 285.798 375.202 405 405 375.202 285.798 256z"></path>
            </svg>
          </div> */}
        </Modal.Header>
        <Modal.Body>
          <div
            className=" "
            style={{
              height: "auto",
              margin: "0 auto",
              width: "100%",
              overflow: "hidden",
            }}
          >
            <div className="row " style={{ height: "100%" }}>
              <div className="col-lg-12 col-md-12 col-12">
                <div className="QR-Section">
                  <div className="Scan">
                    <h3 style={{ textAlign: "center" }}>Scan</h3>
                  </div>
                  <div className="text-center">
                    <QRCode
                      size={256}
                      className="p-2 mb-3"
                      style={{
                        height: "150px",
                        maxWidth: "100%",
                        width: "100%",
                      }}
                      value={props?.qrCode?.url}
                      logoImage={
                        "https://res.cloudinary.com/dbtsrjssc/image/upload/v1711949849/solana-logo-new_padlm8.png"
                      }
                      viewBox={`0 0 256 256`}
                    />
                  </div>
                  <div className="form-horizontal">
                    <div className="form-group login-form-layout">
                      <div className="row mt-5">
                        <div className="col-lg-5 col-md-3 col-12">
                          <label htmlFor="content">Wallet Address:</label>
                        </div>
                        <div className="col-lg-7 col-md-9 col-12">
                          <p className="d-flex">
                            {walletAddress.slice(0, 10)}...
                            {walletAddress.slice(-8)}
                            <CopyToClipboard
                              clipboard={() => copyToClipboard(walletAddress)}
                            />
                          </p>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-lg-5 col-md-3 col-12">
                          <label htmlFor="content">Total:</label>
                        </div>
                        <div className="col-lg-7 col-md-9 col-12">
                          <p>{props?.qrCode?.buyNowPrice} solana </p>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-lg-5 col-md-3 col-12">
                          <label htmlFor="content">Domain:</label>
                        </div>
                        <div className="col-lg-7 col-md-9 col-12">
                          <p>{props?.qrCode?.domainName}</p>
                        </div>
                      </div>
                    </div>

                    <div className="timer-wrapper">
                      <CountdownCircleTimer
                        isPlaying
                        duration={120}
                        size={120}
                        strokeWidth={7}
                        colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
                        colorsTime={[10, 6, 3, 0]}
                        onComplete={() => ({ shouldRepeat: false, delay: 1 })}
                        onUpdate={(remainingTime) =>
                          apiTimerHandler(remainingTime)
                        }
                      >
                        {renderTime}
                      </CountdownCircleTimer>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <StatusModal
        show={statusModal}
        onHide={() => setStatusModal({ ...initalStatus, show: false })}
      />
    </div>
  );
};

export default SolonaPay;
