import React from 'react'
import './CommonUi.css'

const Button = ({children, classData,click, type}) => {

  return (
    <button type={type===undefined?"text":type}
     onClick={click}  className={classData===undefined?"buttonUI":classData}>
        {children}
    </button>
  )
}

export default Button
