import React from "react";
import LandingNavbar from "../../../Common/LandingNavbar";
import Card from "react-bootstrap/Card";
import { NavLink } from "react-router-dom";
import "./Listing.css";
const Listing = () => {
  return (
    <div>
      <LandingNavbar />

      <div className="listingBox">
        <div className="container">
          <h6 className="listingHeading">Select Your Domain Type</h6>
          <div className="listingContainer">
            <div className="listingMain">
              <NavLink to="/domain-auction">
                <Card className="listingCard" style={{ width: "18rem" }}>
                  <div className="listingImageOuter">
                    <Card.Img
                      variant="top"
                      className="listingImage"
                      src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1715426216/Group_27_1_yifjlg.png"
                    />
                  </div>
                  <Card.Body>
                    <Card.Title className="listingTitle">Auction</Card.Title>
                    <Card.Text className="listingText">
                      An auction domain is a sales event wherein potential
                      buyers place competitive bids on domains either in an open
                      or closed format. Auctions are popular because buyers and
                      sellers believe they will get a good deal buying or
                      selling domains.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </NavLink>
            </div>

            <div className="listingMain">
              <NavLink to="/make-offer">
                <Card className="listingCard" style={{ width: "18rem" }}>
                  <div className="listingImageOuter">
                    <Card.Img
                      variant="top"
                      className="listingImage"
                      src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1715426216/Group_26_1_ty0i6h.png"
                    />
                  </div>
                  <Card.Body>
                    <Card.Title className="listingTitle">
                      BuyNow / MakeOffer
                    </Card.Title>
                    <Card.Text className="listingText">
                      Portfolio is where you can list bulk of domains. A domain
                      portfolio is an aggregate of different domain names that
                      are owned either individually or most probably by your
                      corporation.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Listing;
