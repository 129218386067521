import React from "react";
// import UseDebounce from '../../Common/useDebounce'
import useDebounce from "../../Common/useDebounce";
import * as API from "../../util/url";
import { useState, useEffect } from "react";
import { Formik, useFormik } from "formik";
import ApiCaller from "../../util/ApiCaller";

const Contact = () => {
  const [apiInfo, setApiInfo] = useState({
    path: "",
    redirectUrl: "",
  });
  const [domaindata, setDomainData] = useState({});
  //  const formik =useFormik({
  const initialValues = {
    // username: "",
    email: "",
    address: "",
    // phoneNumber: "",
    // zipCode: "",
    city: "",
    country: "",
    state: "",
    // bio: "",
    // image: ""
  };
  const formValues = {
    email: domaindata.email,
    address: domaindata.address,
    city: domaindata.city,
    country: domaindata.country,
    state: domaindata.state,
  };
  const formik = useFormik({
    initialValues: formValues || initialValues,
    onSubmit: async (data, onSubmitProps) => {
      setApiInfo({ path: () => API.updateProfile(data) });
    },
  });
  const onSubmit = async (data, onSubmitProps) => {
    setDomainData({ path: () => API.getProfile(data) });
  };
  //  const onSubmit=async (data, onSubmitProps)=>{
  //   console.log(data,'sau');
  //   setApiInfo({path:()=>API.updateProfile(data)})
  // }

  // const debounce = useDebounce()
  // const test = (data) => {
  //   debounce(() => handleChange(data), 1000);
  // }

  // const handleChange = (data) => {
  //   console.log(data, 'hc');
  // }
  const getProfileData = async () => {
    try {
      let response = await API.getProfile();
      console.log(response.data.map.email, "rsp");
      console.log(response.data.map.address, "rsp");
      console.log(response.data.map.city, "rsp");
      console.log(response.data.map.country, "rsp");
      console.log(response.data.map.state, "rsp");
      setDomainData(response.data.map);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getProfileData();
  }, []);

  return (
    <div className="container">
      <div className="row text-start">
        <div className="col-12 multi_step_form">
          <h3 className="headingtab">Contact Details</h3>
          <hr className="hrlines" />

          <Formik
            onSubmit={onSubmit}
            // validationSchema={validationSchema}
            enableReinitialize
            // initialValues={formValues || initialValues}
          >
            <form className="profile_form" onSubmit={formik.handleSubmit}>
              <label htmlFor="fname">Email-Address:</label>
              <br />
              {/* <div className="input-group mb-3">
              <button className="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">Dropdown</button>
              <ul className="dropdown-menu">
                <li><a className="dropdown-item" href="#">Action</a></li>
              </ul>
              <input type="text" className="form-control" aria-label="Text input with dropdown button" />
            </div> */}
              {/* onChange={(e) => test(e.target.value)} */}
              <input
                type="text"
                id="fname"
                name="email"
                placeholder="abc@gmail.com"
                className="inputuser"
                onChange={formik.handleChange}
                defaultValue={formValues.email}
              />
              <br />
              <br />
              <div className="alert alert-primary" role="alert">
                <div className="row">
                  <div className="col-lg-12 alertcol">
                    <div className=" alertimg">
                      <img
                        src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1707741196/information_teqkek.png"
                        alt=""
                      />
                    </div>
                    <div className=" alerttext">
                      If you opt to hide your last name from the public, Best
                      Urls may still use your full name for verification
                      purposes.
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <label htmlFor="fname " name="address">
                Street Address:
              </label>
              <br />
              <textarea
                id="w3review"
                name="address"
                rows="4"
                cols="50"
                className="textareainput inputusers"
                onChange={formik.handleChange}
                defaultValue={formValues.address}
              ></textarea>
              <br />
              {/* <ReactFlagsSelect
    selected={selected}
    onSelect={(countryCode) => setSelected(countryCode)}

  />;

  {countryCode.map((data) => console.log(data,'dt'))} */}
              <br />
              <label htmlFor="lname">City:</label>
              <br />
              <input
                type="text"
                id="lname"
                name="city"
                className="inputuser"
                onChange={formik.handleChange}
                defaultValue={formValues.city}
              />
              <br />
              <br />
              <label htmlFor="lname">State:</label>
              <br />
              <input
                type="text"
                id="lname"
                name="state"
                className="inputuser"
                onChange={formik.handleChange}
                defaultValue={formValues.state}
              />
              <br /> <br />
              <label htmlFor="lname">Country:</label>
              <br />
              <input
                type="text"
                id="lname"
                name="country"
                className="inputuser"
                onChange={formik.handleChange}
                defaultValue={formValues.country}
              />
              <br />
              <button className=" submitbtns">Save Changes</button>
            </form>
          </Formik>
        </div>
      </div>
      <ApiCaller apiData={apiInfo} />
    </div>
  );
};

export default Contact;
