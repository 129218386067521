import { useEffect } from "react";
import Footer from "../../Common/Footer";
import LandingNavbar from "../../Common/LandingNavbar";
import "../PrivacyPolicy/PrivacyPolicy.css";

const TermsofService = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <LandingNavbar />
      <div className="privacy-page-container">
        <div className="container">
          <div className="privacy-container">
            <div className="privacy-policy-content">
              <h3>Terms of Services</h3>
              <div className="policy-details">
                <p>
                  1. Acceptance of Terms By using SVAD, you agree to these
                  Terms. If you disagree, please do not use our services.
                  <br /> <br />
                  2. Account Registration You must provide accurate information
                  when creating an account and are responsible for safeguarding
                  your password and all activities under your account.
                  <br /> <br />
                  3. User Conduct You are prohibited from using our service for
                  illegal activities, infringing on others' rights, transmitting
                  harmful code, or harassing others.
                  <br /> <br />
                  4. Content Rights You own the content you provide. By posting
                  content, you give us permission to use, distribute, and
                  display this content.
                  <br /> <br />
                  5. Termination We may terminate or suspend your account if you
                  violate these Terms.
                  <br /> <br />
                  6. Disclaimers Our service is provided "as is" without any
                  warranties of accuracy, reliability, or otherwise.
                  <br /> <br />
                  7. Limitation of Liability We are not liable for any indirect
                  damages resulting from your use or inability to use our
                  service.
                  <br /> <br />
                  8. Governing Law These Terms are governed by the laws.
                  <br /> <br />
                  9. Amendments We may update these Terms and will notify you of
                  significant changes.
                  <br /> <br />
                  10. Contact For questions, contact us at [support@svad.com].
                  <br /> <br />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TermsofService;
