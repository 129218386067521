import React,{useEffect, useState} from 'react'
import * as API from "../util/url"
import StatusModal from '../Common/Modal/StatusModal/StatusModal'

const ApiCaller = (props) => {
    // console.log(props,'pr');
    const {path, redirectUrl} = props.apiData;
    // console.log(redirectUrl,'rurl');

   
    const initalStatus={
        show:false,
        status:'',
        message:'',
        url:''
    }
    const [statusModal,setStatusModal] = useState(initalStatus)
    const customApiCall = async()=>{
        try {
            if(path!==''){
                let response = await path()
                if(response.data.map.status===true){
                    setStatusModal({
                        show:true,
                        status:'success',
                        message:response.data.map.message,
                        url:redirectUrl
                    })
                    return 'success'
                }else{
                    setStatusModal({
                        show:true,
                        status:'error',
                        message:response.data.map.message,
                        url:''
                    })
                }
            }

        } catch (error) {
            console.log(error)
           
        }
    }
    useEffect(()=>{
        if(path!==''){
            customApiCall();
            // console.log(path,'pt');
        }
    },[path]);
  return (
    <div>
        <StatusModal show={statusModal} onHide={() => setStatusModal({...initalStatus,show:false})} />
    </div>
  )
}

export default ApiCaller
