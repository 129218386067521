import React from 'react'
import { Field, ErrorMessage } from 'formik'

const CustomFormInput = ({lableType,labelName, fieldName,component}) => {
    return (
        <>
            {/* <label htmlFor={fieldName} className='customLabel' >{labelName}</label> */}
            <Field className="form-control" type={lableType ==='password'?'password': lableType} id={fieldName} name={fieldName} placeholder={labelName} autoComplete="on"  />
            {labelName === 'password' && (labelName === 'password'? <p>password</p> : <p>text</p>) }
            <ErrorMessage name={fieldName} component={component} />
            <br />
        </>
    )
}

export default CustomFormInput
