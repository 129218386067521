import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import "./Login.css";
import * as API from "../../util/url";
import * as Yup from "yup";
import Button from "../../components/ui/Button";
import axios from "axios";
import CustomPasswordInput from "../../components/form/CustomPasswordInput";
import CustomFormInput from "../../components/form/CustomFormInput";
import TextError from "../../components/form/TextError";
import { NavLink, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setAuth } from "../../util/store/slices/authSlice";
import { toast } from "react-toastify";

const initialValues = {
  email: "",
  password: "",
};

const validationSchema = Yup.object({
  email: Yup.string().email("Invalid Email Format").required("Required"),
  password: Yup.string().required("Required"),
});

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [ipAddress, setIpAddress] = useState("");

  const handleIpAddress = async () => {
    try {
      let response = await axios.get("https://api.ipify.org?format=json");
      setIpAddress(response.data.ip);
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmit = async (data, onSubmitProps) => {
    // alert(JSON.stringify(data, null, 2));
    try {
      let response = await API.SignIn({
        email: data.email,
        password: data.password,
        ipAddress: ipAddress,
      });
      if (response.data.map.message === "success") {
        localStorage.setItem(
          "bestUrl",
          response.data.map.accesstoken.accessToken
        );
        localStorage.setItem("userName", response.data.map.Name);
        localStorage.setItem("email", response.data.map.email);
        localStorage.setItem("uId", response.data.map.uId);

        dispatch(
          setAuth({
            userName: response.data.map.Name,
            accessToken: response.data.map.accesstoken.accessToken,
          })
        );
        if (response.data.map.Role[0].name === "ADMIN") {
          localStorage.setItem("grant", response.data.map.Role[0].name);

          navigate("/power/dashboard");
        } else {
          navigate("/");
        }
      } else {
        toast(response.data.map.message, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "colored",
        });
        console.log(response.data.map.message);
      }
      onSubmitProps.resetForm();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleIpAddress();
  }, []);

  return (
    <section className="login-page " id="loginForm">
      <div className="loginOuter container">
        <div className="form-Layout">
          <h1 className="loginHeading"> Welcome Back! </h1>

          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            enableReinitialize
          >
            <Form className="form-group login-form-layout" action="">
              <CustomFormInput
                lableType="text"
                labelName="Email"
                fieldName="email"
                component={TextError}
              />
              <CustomPasswordInput />
              <Button type="submit" classData="btn loginButton">
                Submit
              </Button>

              <p className="text-center mt-2 already-acc-login">
                Don't have an account?{" "}
                <NavLink to="/signup" className="login-btn">
                  Sign up
                </NavLink>
              </p>
            </Form>
          </Formik>
        </div>
      </div>
    </section>
  );
};

export default Login;
