import React, { useState, useEffect } from "react";
import * as API from "../../../util/url";
import PageSize from "../../../Common/PageSize";
import ProjectPagination from "../../../Common/Pagination/ProjectPagination";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import TableComponent from "../../../Common/TableComponent/TableComponent";

const Admin_Roles = () => {
  const [accountData, setAccountData] = useState([]);
  const [singleHead, setSingleHead] = useState([]);

  const singleHeadHandler = (data) => {
    setSingleHead(Object.keys(data));
  };

  const tableColumns = [
    singleHead.map((data) => {
      console.log(data);
      return {
        accessorKey: "name", // the key to access the data
        header: "name",
        cell: (props) => <div>{props.getValue()}</div>,
      };
    }),
  ];
  const navigate = useNavigate();

  const [pageSize, setPageSize] = useState("10");
  const [pageCount, setPageCount] = useState(0);
  const [dataCount, setDataCount] = useState(0);

  const getAllAccount = async (
    pageNumber = 1,
    keyValue = {},
    status,
    order
  ) => {
    try {
      let response = await API.getRoles({
        pageNo: pageNumber,
        pageSize: pageSize,
        keyValue,
      });
      console.log(response.data, "allDomains");
      setAccountData(response.data.data);
      setPageCount(response.data.size / pageSize);
      setDataCount(response.data.size);
      singleHeadHandler(response.data.data[0]);
    } catch (error) {
      console.log(error);
    }
  };
  const handlePageSize = (data) => setPageSize(data);

  useEffect(() => {
    getAllAccount();
  }, [pageSize]);
  const keywordFilter = (searchData) => {
    console.log(searchData);
    let obj = {};
    {
      singleHead?.map((data) => {
        // console.log({[data]:searchData});
        Object.assign(obj, { [data]: searchData });
      });
    }

    getAllAccount(1, obj);
  };

  return (
    <div>
      <div className="page_tittle">
        <h3 className="px-3">Roles</h3>
      </div>
      <div
        className="d-flex"
        style={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <div className="showing">
          <h6>Showing {pageSize} items</h6>
        </div>
        <div className="searchcol px-3">
          <input
            className="searchinput"
            type="text"
            placeholder="Search.. "
            onChange={(e) => keywordFilter(e.target.value)}
          />
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-12">
          <div className="table-container container">
            <table className="table tablediv table-hover acutiontble table-striped">
              <thead className="headertable">
                <tr>
                  {singleHead?.map((data) => {
                    return <th scope="col">{data}</th>;
                  })}
                </tr>
              </thead>
              <tbody>
                {accountData.map((values) => {
                  console.log(values);
                  return (
                    <tr>
                      {Object.keys(accountData[0]).map((data) => {
                        return (
                          <td>
                            {" "}
                            {typeof values[data] == "boolean"
                              ? values[data]
                                ? "true"
                                : "false"
                              : values[data]}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <TableComponent
        data={accountData}
        columns={tableColumns}
        // sorting={sorting}
        // setSorting={setSorting}
        // filter={(e) => tableFilter(e)}
      />

      <div style={{ position: "relative", height: "100px" }}>
        {dataCount > pageSize && (
          <ProjectPagination
            handlePageClick={(e) => getAllAccount(e.selected + 1)}
            pageCount={pageCount}
          />
        )}

        <div className="pageSizeContainer">
          <PageSize
            title={`${pageSize} items`}
            handleData={(e) => handlePageSize(e)}
          />
        </div>
      </div>
    </div>
  );
};

export default Admin_Roles;
