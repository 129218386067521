import axios from "axios";

const baseUrl = () => {
  if (process.env.NODE_ENV === "development") {
    return "http://localhost:5000";
  } else if (process.env.NODE_ENV === "production") {
    return "/";
  }
};

const API = axios.create({
  baseURL: baseUrl(),
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

// It helps to send Token through headers
API.interceptors.request.use((req) => {
  if (localStorage?.getItem("bestUrl")) {
    req.headers["Authorization"] = `Bearer ${localStorage.getItem("bestUrl")}`;
  }
  return req;
});

// Auth Api
export const SignUp = (data) => API.post("/auth/SignUp", data);
export const SignIn = (data) => API.post("/auth/SignIn", data);

export const DomainAuction = (data) =>
  API.post("/domain/auction-domains", data);
export const getAuctionDomains = (data) =>
  API.post("/domain/getAuctionDomains", data);
export const buyNowDomains = (data) =>
  API.post("/domain/buy-now-domains", data);
export const getDomainPortfolio = (data) =>
  API.post("/domain/getDomainPortfolio", data);
export const filterMarketPlace = (data) =>
  API.post("/filter/filterMarketPlace", data);
export const filterGetDetails = (data) => API.post("/filter/get-details", data);
export const getHomePageData = (data) =>
  API.get("/filter/get-home-page-data", data);

export const getFrontData = (data) =>
  API.post("/filter/getFrontData", data);

export const makeOffer = (data) => API.post("/offer/makeOffer", data);
export const getMakeOffers = (data) => API.post("/offer/getMakeOffers", data);

export const bidPlaced = (data) => API.post("/bidding/bid", data);
export const getBiddingData = (data) =>
  API.post("/bidding/getBiddingData", data);
export const singleUserBiddingData = (data) =>
  API.post("/bidding/getBiddingHistoryForUser", data);
export const getProductData = (data) =>
  API.post("/api/user/get-product-data", data);
export const deleteProductData = (data) =>
  API.post("/api/user/delete-product", data);

export const updateDomainPortfolio = (data) =>
  API.post("/domain/updateDomainPortfolio", data);
export const deleteDomainPortfolio = (data) =>
  API.post("domain/deleteDomains", data);
export const getDashboardData = (data) =>
  API.get("api/user/getSellerDashboardData", data);
export const updateProfile = (data) => API.post("api/user/updateProfile", data);
export const getProfile = (data) => API.get("/api/user/getProfile", data);
export const getTransactionMessages = (data) =>
  API.post("/offer/getTransactionMessages", data);
export const getTransactionDetailMessages = (data) =>
  API.post("/offer/getTransactionDetailMessage", data);
export const sendMessageSeller = (data) =>
  API.post("/offer/getTransactionDetailMessage", data);
export const getnotification = (data) =>
  API.post("/admin/getNotificationData", data);
export const getMakeOfferMessageDashboard = (data) =>
  API.post("/offer/getMakeOfferMessages", data);
export const sendMakeOfferMessageDashboard = (data) =>
  API.post("/offer/makeOfferResponse", data);
export const makeOfferBuyer = (data) => API.post("/offer/makingOffer", data);
export const getUserNotification = (data) =>
  API.get("api/user/getNotification", data);
export const updateNotification = (data) =>
  API.post("api/user/updateNotification", data);

export const DomainSold = (data) =>
  API.post("api/user/get-sold-products", data);

export const PaymentPurchase = (data) =>
  API.post("api/user/get-purchased-products", data);


// Admin Dashboard
export const adminDashboarData = (data) =>
  API.get("/admin/getDashboardAnalytics", data);
export const allAccounts = (data) => API.post("admin/getAllUsers", data);
export const spamUser = (data) => API.post("/admin/spamUser", data);
export const googleAuth = (data) => API.post("/admin/googleAuth", data);
export const loginUser = (data) => API.post("/admin/login-user", data);
export const getAllAuctionListing = (data) =>
  API.post("/admin/get-all-auction-listing", data);
export const verifyProduct = (data) => API.post("/admin/verify-product", data);
export const setPremium = (data) => API.post("/admin/set-premium", data);
export const setFeature = (data) => API.post("/admin/set-featured", data);

export const setTopDomains = (data) => API.post("/admin/set-topDomain", data);
export const setShowcaseDomains = (data) => API.post("/admin/set-showcase", data);


export const deleteProduct = (data) => API.post("/admin/delete-product", data);
export const getAllDomainPortfolio = (data) =>
  API.post("/admin/getDomainPortfolio", data);
export const masterBidding = (data) => API.post("/admin/getBiddingData", data);
export const masterDomains = (data) => API.post("admin/getDomainData", data);
export const masterFilter = (data) => API.post("admin/getFilterData", data);
export const masterMakeOfferMessage = (data) =>
  API.post("admin/getMakeOfferMsgData", data);
export const masterMakeOfferData = (data) =>
  API.post("admin/getMakeOfferData", data);

export const getMakeOffer = (data) => API.post("/admin/getMakeOffer", data);
export const getMakeOfferMessage = (data) =>
  API.post("/admin/getMakeOfferMessages", data);
export const sendMakeOfferMessageAdmin = (data) =>
  API.post("/admin/sendMakeOfferMessageAdmin", data);
export const getAdmin = (data) => API.post("/admin/getUserData", data);
export const getWatchers = (data) => API.post("/admin/getWatchersData", data);
export const getRoles = (data) => API.post("/admin/getRolesData", data);
export const getUserRoles = (data) => API.post("/admin/getUserRolesData", data);
export const getContactus = (data) => API.post("/admin/getContactUsData", data);
export const getDomainReportData = (data) =>
  API.post("/admin/getDomainReportData", data);
export const getDomainReportStatus = (data) =>
  API.post("/admin/getDomainReportStatusData", data);
export const getadminemaildata = (data) =>
  API.post("/admin/getEmailsData", data);
export const getadminpatterndata = (data) =>
  API.post("/admin/getPatternData", data);
export const getpaymentmessage = (data) =>
  API.post("/admin/getPaymentMessageData", data);
export const getpaymentmainchatdata = (data) =>
  API.post("/admin/getPaymentMainChatData", data);

export const paymenttarckdata = (data) =>
  API.post("/admin/getPaymentTrackData", data);
export const updateBuyNowDomains = (data) =>
  API.post("/admin/updateBuyNowDomains", data);

export const AdminPayment = (data) =>
  API.post("admin/getPaymentHistory", data);



export const solGetQrCode = (data) => API.post(`/sol/getQRcode`, data);
export const verifyQrCode = (data) => API.post(`/sol/getVerify`, data);
export const getEmailData = (data) => API.get("admin/getEmailContent", data);
