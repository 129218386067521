import React from 'react'
import './Verification.css'
const Verification = () => {
    return (
        <div className="container">
            <div className="row">
                <div className="col-12">
                   

                    {/* <div className="form-horizontal text-center">
                        <div className="form-group">
                            <label className="control-label col-sm-2" for="content" style={{ width: "100%" }}>Enter your One time password to activate</label>
                            <div className="col-sm-10 text-center">
                                <input type="text" className="form-control qrinput" id="content" placeholder="Enter OTP.." />
                            </div>
                        </div>
                        <div className="form-group text-center">
                            <div className="col-sm-offset-2 col-sm-10">
                                
                            </div>
                        </div>
                    </div> */}


  {/* Multi step form */}
  <section className="multi_step_form">
    <form id="msform">
      {/* Tittle */}
      <div className="tittle">
        <h2>Verification Process</h2>
        <p>
          In order to use this service, you have to complete this verification
          process
        </p>
      </div>
      {/* progressbar */}
      <div className="text-center">
                        <h3 className="mt-3 mb-3 verifyheading" >Google Authentication</h3>
                        <img src="https://chart.googleapis.com/chart?cht=qr&chl=Hello+World&chs=160x160&chld=L|0"
                            className="qr-code img-thumbnail img-responsive" />
                    </div>
      {/* fieldsets */}
      <fieldset>
       
       
        <div className="done_text">
          <a href="#" className="don_icon">
            {/* <i className="ion-android-done" /> */}
            <img src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1706188698/Asset_7_yhjvbd.png" alt="" />
          </a>
          <h6>
            A secret code is sent to your phone. <br />
            Please enter it here.
          </h6>
        </div>
        <div className="code_group">
          <input type="text" className="form-control" placeholder={0} />
          <input type="text" className="form-control" placeholder={0} />
          <input type="text" className="form-control" placeholder={0} />
          <input type="text" className="form-control" placeholder={0} />
        </div>
        <button type="button" className="action-button previous_button">
          Back
        </button>
        <button type="button" className="next action-button">
          Continue
        </button>
      </fieldset>
      

    </form>
  </section>
  {/* End Multi step form */}


                </div>
            </div>
        </div>
    )
}

export default Verification
