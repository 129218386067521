import React from "react";
import "./PageNotFound.css";
import { NavLink } from "react-router-dom";

const PageNotFound = () => {
  return (
    <div id="page-not-found">
      <div className="number-found">404</div>
      <div className="text-found">
        <span>Ooops...</span>
        <br />
        Page not found
      </div>
      <div className="mt-4">
        <NavLink to="/" className="page-not-found-btn">
          Home{" "}
        </NavLink>
      </div>
    </div>
  );
};

export default PageNotFound;
