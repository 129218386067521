import React, { useState } from "react";
import "./DomainAuction.css";
import { Formik, Form } from "formik";
import * as API from "../../../util/url";
import * as Yup from "yup";
import Button from "../../../components/ui/Button";
import LandingNavbar from "../../../Common/LandingNavbar";
import TextError from "../../../components/form/TextError";
import { Field, ErrorMessage } from "formik";
import { FaRegClock } from "react-icons/fa6";
import { BsPencilSquare } from "react-icons/bs";
import ApiCaller from "../../../util/ApiCaller";
import { useLocation, useNavigate } from "react-router-dom/dist";

const DomainAuction = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [apiInfo, setApiInfo] = useState({
    path: "",
    redirectUrl: "",
  });

  const initialValues = {
    domainName: "",
    tagline: "",
    summary: "",
    startingPrice: "",
    reservePrice: "",
    days: "",
    buyNowPrice: "",
  };
  const formValues = {
    domainName: location?.state?.domainName,
    tagline: location?.state?.tagline,
    summary: location?.state?.summary,
    startingPrice: location?.state?.startingPrice,
    reservePrice: location?.state?.reservePrice,
    days: location?.state?.days,
    buyNowPrice: location?.state?.buyNowPrice,
    // domainId:location?.state?.
  };

  const validationSchema = Yup.object({
    domainName: Yup.string().required("*Domain name is required."),
    tagline: Yup.string().required("* Please enter some tagline."),
    summary: Yup.string().required("* Please enter summary."),
    startingPrice: Yup.string().required("* Starting price is required"),
    reservePrice: Yup.string()
      .test(
        "reservePriceCompare",
        `ReservePrice must be bigger then StartingPrice`,
        function (reservePrice) {
          return reservePrice
            ? parseInt(reservePrice) > parseInt(this.parent.startingPrice)
            : true;
        }
      )
      .required("* Reserve price is required"),
    days: Yup.string()
      .test("daysCompare", `Days must be Greater 0`, function (days) {
        return days ? parseInt(days) > 0 : true;
      })
      .required("* Duration is required"),
  });

  const onSubmit = async (data, onSubmitProps) => {
    setApiInfo({
      path: () => API.DomainAuction(data),
      redirectUrl: "/dashboard/auction",
    });
  };
  useState(() => {
    navigate(location.pathname, {});
  }, []);

  return (
    <div>
      <LandingNavbar />
      <section className="domainAuction pt-4">
        <div className="domainauction-form">
          <div className="container">
            <div className="row">
              <div className="col-md-8 domain-auction-form">
                <div className="auctionForm ">
                  <Formik
                    onSubmit={onSubmit}
                    validationSchema={validationSchema}
                    enableReinitialize
                    initialValues={formValues || initialValues}
                  >
                    <Form className="form-group" action="">
                      <div className="row">
                        <div className="col-md-6">
                          <label
                            htmlFor={"domainName"}
                            className="auctionFormLabel"
                          >
                            Domain Name
                          </label>
                          <Field
                            className="form-control auctionFormInput"
                            type={"text"}
                            id={"domainName"}
                            name={"domainName"}
                            placeholder={"Enter Domain Name"}
                            autoComplete="on"
                          />
                          <ErrorMessage
                            name={"domainName"}
                            component={TextError}
                          />
                          <br />
                        </div>

                        <div className="col-md-6">
                          <label
                            htmlFor={"tagline"}
                            className="auctionFormLabel"
                          >
                            Tagline
                          </label>
                          <Field
                            className="form-control auctionFormInput"
                            type={"text"}
                            id={"tagline"}
                            name={"tagline"}
                            placeholder={"Enter tagline of project"}
                            autoComplete="on"
                          />
                          <ErrorMessage
                            name={"tagline"}
                            component={TextError}
                          />
                          <br />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-12">
                          <label
                            htmlFor={"summary"}
                            className="auctionFormLabel"
                          >
                            Summary
                          </label>
                          <Field
                            className="form-control auctionFormInput "
                            as="textarea"
                            rows={5}
                            id={"summary"}
                            name={"summary"}
                            placeholder={"Summary"}
                            autoComplete="on"
                          />
                          <ErrorMessage
                            name={"summary"}
                            component={TextError}
                          />
                          <br />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <label
                            htmlFor={"startingPrice"}
                            className="auctionFormLabel"
                          >
                            Starting Price{" "}
                          </label>
                          <div className="input-group mb-3">
                            <span
                              className="input-group-text"
                              id="basic-addon1"
                            >
                              $
                            </span>
                            <Field
                              className="form-control auctionFormInput"
                              type={"number"}
                              id={"startingPrice"}
                              name={"startingPrice"}
                              placeholder={"0"}
                              autoComplete="on"
                            />
                          </div>
                          <ErrorMessage
                            name={"startingPrice"}
                            component={TextError}
                          />
                          <br />
                        </div>

                        <div className="col-md-6">
                          <label
                            htmlFor={"reservePrice"}
                            className="auctionFormLabel"
                          >
                            Reserve Price{" "}
                          </label>
                          <div className="input-group mb-3">
                            <span
                              className="input-group-text"
                              id="basic-addon1"
                            >
                              $
                            </span>
                            <Field
                              className="form-control auctionFormInput"
                              type={"number"}
                              id={"reservePrice"}
                              name={"reservePrice"}
                              placeholder={"0"}
                              autoComplete="on"
                            />
                          </div>
                          <ErrorMessage
                            name={"reservePrice"}
                            component={TextError}
                          />
                          <br />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <label htmlFor={"days"} className="auctionFormLabel">
                            Auction days (in Days)
                          </label>
                          <div className="input-group mb-3">
                            <span
                              className="input-group-text"
                              id="basic-addon1"
                            >
                              <FaRegClock />
                            </span>
                            <Field
                              className="form-control auctionFormInput"
                              type={"number"}
                              id={"days"}
                              name={"days"}
                              placeholder={"0"}
                              autoComplete="on"
                            />
                          </div>
                          <ErrorMessage name={"days"} component={TextError} />
                          <br />
                        </div>

                        <div className="col-md-6">
                          <label
                            htmlFor={"buyNowPrice"}
                            className="auctionFormLabel"
                          >
                            Buynow price (Optional){" "}
                          </label>
                          <div className="input-group mb-3">
                            <span
                              className="input-group-text"
                              id="basic-addon1"
                            >
                              $
                            </span>
                            <Field
                              className="form-control auctionFormInput"
                              type={"number"}
                              id={"buyNowPrice"}
                              name={"buyNowPrice"}
                              placeholder={"0"}
                              autoComplete="on"
                            />
                          </div>
                          <ErrorMessage
                            name={"buyNowPrice"}
                            component={TextError}
                          />
                          <br />
                        </div>
                      </div>

                      <div className="d-flex" style={{ justifyContent: "end" }}>
                        <Button
                          type="submit"
                          classData="btn loginButton w-auto"
                        >
                          <BsPencilSquare />{" "}
                          {location.state !== null ? "update" : "create"}{" "}
                        </Button>
                      </div>
                    </Form>
                  </Formik>
                </div>
              </div>
              <div className="col-md-4 domain-auction-formtext">
                <h6 className="listingTitle text-start">
                  How much amount would you set to sell it for ?
                </h6>
                <p className="text-start auctionText">
                  Set the starting price and reserve price for your domain
                </p>

                <p className="text-start auctionText mt-3">
                  Explain your domain in a sentence. It comes at notice first in
                  listing. Give a detailed information about your app so that
                  buyers would be able to understand what you are selling
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ApiCaller apiData={apiInfo} />
    </div>
  );
};

export default DomainAuction;
