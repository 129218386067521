import React from 'react'
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';


const PageSize = ({title,handleData}) => {
    return (
        <DropdownButton  className='mx-2' title={title}>
            <Dropdown.Item onClick={() => handleData('10')}>10 items</Dropdown.Item>
            <Dropdown.Item onClick={() => handleData('25')}>25 items</Dropdown.Item>
            <Dropdown.Item onClick={() => handleData('50')}>50 items</Dropdown.Item>
            <Dropdown.Item onClick={() => handleData('100')}>100 items</Dropdown.Item>
        </DropdownButton>
    )
}

export default PageSize

