import React from "react";
import { Link, NavLink } from "react-router-dom";
// import Footer from "../../Footer";
const Draft = () => {
  return (
    <div>
      {/* <div className="container"> */}
      <div className="row">
        <div className="col-lg-12 col-md-12 col-12 auctiontable">
          <div className="page_tittle">
            <h3>Draft</h3>
          </div>
          <div className="pagebody">
            <div className="row">
              <div className="col-lg-4 col-md-4 col-12 showing">
                <h6>Showing 0 result</h6>
              </div>
              <div className="col-lg-4 col-md-4 col-12 searchcol">
                <input
                  className="searchinput"
                  type="text"
                  placeholder="Search.. "
                />
              </div>
              <div className="col-lg-4 col-md-4 col-12">
                <div className="dropdown dp">
                  <button
                    className="btn dropbtn dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false "
                  >
                    Select Product Type
                  </button>
                  <ul className="dropdown-menu">
                    <li>
                      <a className="dropdown-item" href="#">
                        <input
                          type="checkbox"
                          id="vehicle1"
                          name="vehicle1"
                          value="Bike"
                        />
                        <label for="vehicle1" style={{ padding: "7px" }}>
                          {" "}
                          Domain
                        </label>
                        <br />
                      </a>
                    </li>

                    <li>
                      <a className="dropdown-item" href="#">
                        <input
                          type="checkbox"
                          id="vehicle1"
                          name="vehicle1"
                          value="Bike"
                        />
                        <label for="vehicle1" style={{ padding: "7px" }}>
                          Starter
                        </label>
                        <br />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-12">
                <div className="table-container container">
                  <table className="table tablediv table-hover acutiontble table-striped">
                    <thead className="headertable">
                      <tr>
                        <th scope="col">Product Name</th>
                        <th scope="col">Product Type</th>
                        <th scope="col">Creation date</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody></tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          {/* <table>
        <tr>
            <th>Name</th>
            <th>Age</th>
            <th>Gender</th>
        </tr>
        <tr>
            <td>Anom</td>
            <td>19</td>
            <td>Male</td>
        </tr>
        <tr>
            <td>Megha</td>
            <td>19</td>
            <td>Female</td>
        </tr>
        <tr>
            <td>Subham</td>
            <td>25</td>
            <td>Male</td>
        </tr>
    </table> */}
        </div>
      </div>
      {/* </div> */}
      {/* <Footer /> */}
    </div>
  );
};

export default Draft;
