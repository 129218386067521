import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { CgCloseO } from "react-icons/cg";
import Button from "../../../components/ui/Button";
import * as API from "../../../util/url";
import * as Yup from "yup";
import CustomFormInput from "../../../components/form/CustomFormInput";
import { NavLink, useNavigate } from "react-router-dom";
import TextError from "../../../components/form/TextError";
import "./MakeOfferModal.css";
import ApiCaller from "../../../util/ApiCaller";
import { ModalHeader } from "react-bootstrap";

const MakeOfferModal = (props) => {
  const [apiInfo, setApiInfo] = useState({
    path: "",
    redirectUrl: "",
  });
  const [makeOfferPrice, setMakeOfferPrice] = useState(0);
  console.log(props.makeOfferModalData, "mod");
  const { minimumOffer, domainId, domainName } = props.makeOfferModalData;
  const initialValues = {
    // price:null,
    termsAndConditions: false,
  };
  const validationSchema = Yup.object({
    // price:Yup.number().required("* Price is required"),/
    termsAndConditions: Yup.bool().oneOf(
      [true],
      "* Accept terms and conditions"
    ),
  });

  const onPriceChange = (e) => {
    setMakeOfferPrice(e);
    if (e < minimumOffer) {
      document.getElementById("PriceError").style.display = "block";
    } else {
      document.getElementById("PriceError").style.display = "none";
    }
  };

  const onSubmit = async (data, onSubmitProps) => {
    setApiInfo({
      path: () =>
        API.makeOffer({
          domainId: domainId,
          price: makeOfferPrice,
          // domainName:domainName
        }),
      redirectUrl: "/dashboard/offer",
    });

    // onSubmitProps.resetForm();
  };

  // const onSubmit = async (data, onSubmitProps) => {
  //   console.log(data,makeOfferPrice ,'mop');
  //   alert(JSON.stringify(data, null, 2));

  //   try {
  //       let response = await API.SignUp({
  //           name: data.name,
  //           email: data.email,
  //           password: data.password
  //       })
  //       console.log(response, 'rsp')
  //       navigate(`/`);
  //       onSubmitProps.resetForm();

  //   } catch (error) {
  //       console.log(error)
  //   }
  // };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-border"
    >
      <ModalHeader closeButton>
        <h3 style={{ fontSize: "18px" }}>Make offer on domain</h3>
      </ModalHeader>
      <Modal.Body className="p-0 border-0">
        <div className="modal-content p-3  modal-makeoffer-card">
          {/* <div className="modal-header border-0 p-0 d-block text-end">
            <CgCloseO
              className="mx-2 mt-1"
              style={{ fontSize: "22px", cursor: "pointer" }}
              onClick={() => props.onHide()}
            />
          </div> */}
          <div className="modal-body p-0">
            <div className="card border-0">
              <div
                className="card-header bg-white"
                style={{ padding: "0 1.5rem" }}
              >
                <div
                  className="d-flex"
                  style={{ justifyContent: "space-between" }}
                >
                  <h4 style={{ fontSize: 20, fontWeight: 600 }}>
                    {domainName}
                  </h4>
                  <h4
                    className="ml-auto"
                    style={{
                      // color: "#01c065",
                      fontSize: "19px",
                      fontWeight: 600,
                    }}
                  >
                    ${minimumOffer}
                  </h4>
                </div>
              </div>
              <div className="card body p-4 pt-0 border-0">
                <p className="mt-2" style={{ fontSize: "16px" }}>
                  If you are interested in purchasing this domain name, please
                  submit your offer here.
                </p>
                <div>
                  <Formik
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    validationSchema={validationSchema}
                    enableReinitialize
                  >
                    <Form className="form-group make-offer-form" action="">
                      <div className="input-group mb-3">
                        <span className="input-group-text" id="basic-addon1">
                          $
                        </span>
                        <Field
                          className="form-control text-black"
                          onChange={(e) => onPriceChange(e.target.value)}
                          type="number"
                          id={"price"}
                          name={"price"}
                          autoComplete="on"
                        />
                      </div>
                      <ErrorMessage name={"price"} component={TextError} />
                      <div
                        id="PriceError"
                        className="errorMsg mt-1 priceError"
                        style={{
                          color: "red",
                          textAlign: "left",
                          fontSize: "13px",
                        }}
                      >
                        * Price must be greater than the ${minimumOffer}
                      </div>
                      <br />

                      <div className="mb-2">
                        <Field type="checkbox" name="termsAndConditions" />
                        <label className="ms-2">
                          I Agree to
                          <a href="/terms" className="ms-1w">
                            Terms &amp; Conditions
                          </a>
                        </label>
                        <ErrorMessage
                          name={"termsAndConditions"}
                          component={TextError}
                        />
                      </div>

                      <Button type="submit" classData="btn loginButton">
                        Submit
                      </Button>
                    </Form>
                  </Formik>
                </div>
              </div>
            </div>
          </div>
          <ApiCaller apiData={apiInfo} />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default MakeOfferModal;
