import React, { useEffect, useState } from 'react'
import './MakeOfferMessage.css'
import { useLocation, useNavigate } from 'react-router-dom';
import * as API from "../../../util/url";
import { MdOutlineArrowBack } from "react-icons/md";
import Moment from "moment";
const MakeOfferMessage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [messageData, setMessageData] = useState({});
  const [message, setMessage] = useState("");
  console.log(location,'loc')
  const viewMessageHandler = async() =>{
    try {
      let response = await API.getMakeOfferMessage({
          makeId:location.state
      })
      console.log(response.data.map,'rvm');
      setMessageData(response.data.map)
      if (response.data.success === true) {
        scrollDown();
      }
    
    } catch (error) {
      console.log(error)
    }
  }
  const SendMessage = async() =>{
    document.getElementById('Message').value = "";
    try {
      let response = await API.sendMakeOfferMessageAdmin({
          id:location.state,
          message:message
      })
      console.log(response.data.map,'rvm');
      
      setMessageData(response.data.map)
      if (response.data.map.status === true) {
        viewMessageHandler();
      }
    } catch (error) {
      console.log(error)
    }
  }
  const messageContain= (e) =>{
    setMessage(e.target.value)
  }
  function scrollDown() {
    setTimeout(() => {
      document.getElementById('chatBox').scrollTop = document.getElementById('chatBox').scrollHeight
    }, 20);
  }
  
  useEffect(()=>{
    viewMessageHandler();
    scrollDown()
  },[])
  return (
    <div className='container'>
       <div className="chatData">
                <section className="msger">
                  <header className="msger-header">
                    <div className="msger-header-title d-flex">
                      <div onClick={()=>navigate('/power/dashboard/admin-get-make-offer')} style={{paddingRight:'10px', cursor:'pointer'}}> <MdOutlineArrowBack /></div>
                      <i className="fas fa-comment-alt" /> {messageData.productName}
                    </div>
                    <div className="msger-header-options">
                      {/* <span>
                      <i className="fas fa-cog" />
                    </span> */}
                    </div>
                  </header>
                  <main id='chatBox' className="msger-chat">

                    {messageData?.makeOffer?.map((data) => {

                      if (data.sendBy === 'buyer') {
                        return <>
                          <div className="msg left-msg">
                          <div
                            className="msg-img1"
                            style={{
                              backgroundImage:
                                "url(https://image.flaticon.com/icons/svg/145/145867.svg)"
                            }}
                          >
                             {messageData.sellerName?.split('')[0]}
                             </div>
                         
                            <div className="msg-bubble">
                            <div className="msg-text text-start designLeft">
                                <div>{data.subject}</div> 
                                <div>${data.price}</div>
                              </div>
                              <div className="msg-info">
                                <div className="msg-info-name " style={{color:'gray'}}>{messageData.userName}</div>
                                <div className="msg-info-time">
                                    {Moment(data.time).format("ll")}
                                    
                                    </div>
                              </div>
                             
                            </div>
                          </div>

                        </>
                      } else {
                        return  <div className="msg right-msg">
                            <div
                              className="msg-img"
                              style={{
                                backgroundImage:
                                  "url(https://res.cloudinary.com/dowsba1cv/image/upload/v1631884636/digitalkingdom_mch40q.png)"
                              }}
                            />
                          <div className="msg-bubble">
                          <div className="msg-text text-start designRight">
                          <div>{data.subject}</div> 
                          {data.sendBy==='admin'?data.message:<div>${data.price}</div>}
                                
                            </div>
                            <div className="msg-info">
                              <div className="msg-info-name " style={{color:'gray'}}>
                                {data.sendBy==='seller'?messageData.sellerName:"BestUrls"}
                             
                                </div>
                              <div className="msg-info-time">
                                {Moment(data.time).format("ll")}
                                </div>

                            </div>
                         
                          </div>
                        </div>
                        }
                     })} 
                  </main>
                  <form className="msger-inputarea">
                    <input
                      id='Message'
                      type='text'
                      className="msger-input"
                      placeholder="Enter your message..."
                      onChange={(e) => messageContain(e)}

                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                          SendMessage(message)
                        }
                      }}


                    />
                    <button type="button" 
                    onClick={() => SendMessage(message)} 
                    className="msger-send-btn">
                      Send
                    </button>
                  </form>
                </section>
              </div>
    </div>
  )
}

export default MakeOfferMessage
