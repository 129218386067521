import { useEffect } from "react";
import Footer from "../../Common/Footer";
import LandingNavbar from "../../Common/LandingNavbar";
import "./PrivacyPolicy.css";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <LandingNavbar />
      <div className="privacy-page-container">
        <div className="container">
          <div className="privacy-container">
            <div className="privacy-policy-content">
              <h3>Privacy Policy</h3>
              <div className="policy-details">
                <p>
                  1. Introduction
                  <br />
                  Welcome to Solana Valued Aftermarket for Domains (SVAD). We
                  respect your privacy and are committed to protecting your
                  personal information. This privacy policy outlines our
                  practices concerning the collection, use, and disclosure of
                  your information through the use of our website, www.svad.com
                  (the "Site"), which is provided by [Your Company Name].
                  <br />
                  <br />
                  2. Information We Collect
                  <br />
                  We collect various types of information in connection with the
                  services we provide. This includes:
                  <br />
                  Personal Information: Information you provide to us directly,
                  such as your name, email address, and telephone number when
                  you register for an account or participate in our online
                  forums.
                  <br />
                  Transactional Information: Details of transactions you carry
                  out through our Site, including the submission of domain
                  offers and purchases.
                  <br />
                  Technical Information: Automatically collected information
                  about your interaction with our Site, including IP addresses,
                  browser types, and information about your device.
                  <br />
                  <br />
                  3. How We Use Your Information
                  <br />
                  Your information is used for the following purposes:
                  <br />
                  To provide and manage the services you request.
                  <br />
                  To improve our website and services by analyzing how our
                  services are used.
                  <br />
                  To communicate with you, including responding to your
                  inquiries and updating you on changes to our service.
                  <br />
                  <br />
                  4. Sharing Your Information
                  <br />
                  We may share your information with third parties in the
                  following ways:
                  <br />
                  Service Providers: We share information with companies
                  providing services on our behalf, such as hosting and
                  maintenance, analysis, payment processing, marketing, and
                  promotions.
                  <br />
                  Legal Requirements: We disclose information if required to do
                  so by law or in response to a subpoena or court order.
                  <br />
                  Business Transfers: In connection with a merger, acquisition,
                  or sale of assets, your information may be transferred as part
                  of that deal.
                  <br />
                  5. Your Choices and Rights
                  <br />
                  You have rights regarding the use of your personal
                  information, including the right to access, correct, or delete
                  the information we hold about you. To exercise these rights,
                  please contact us at [privacy@svad.com].
                  <br />
                  <br />
                  6. Security of Your Information
                  <br />
                  We take reasonable measures to protect your information from
                  loss, theft, misuse, and unauthorized access. However, no
                  Internet or email transmission is ever fully secure or
                  error-free. Please keep this in mind when disclosing any
                  personal information to us via the internet.
                  <br />
                  <br />
                  7. Changes to Our Privacy Policy
                  <br />
                  We may update this privacy policy from time to time in
                  response to legal, technical, or business developments. When
                  we update our privacy policy, we will take appropriate
                  measures to inform you, consistent with the significance of
                  the changes we make.
                  <br />
                  <br />
                  8. Contact Information
                  <br />
                  If you have any questions or concerns about this privacy
                  policy or our handling of your personal information, please
                  contact us at [privacy@svad.com].
                  <br />
                  <br />
                  9. Consent
                  <br />
                  By using our site, you consent to our privacy policy.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
