import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import "./Login.css";
import * as API from "../../util/url";
import * as Yup from "yup";
import Button from "../../components/ui/Button";
import CustomPasswordInput from "../../components/form/CustomPasswordInput";
import CustomFormInput from "../../components/form/CustomFormInput";
import TextError from "../../components/form/TextError";
import { NavLink, useNavigate } from "react-router-dom";

const initialValues = {
  email: "",
  password: "",
  name: "",
  termsAndConditions: false,
};

const validationSchema = Yup.object({
  name: Yup.string().required("Required"),
  email: Yup.string().email("Invalid Email Format").required("Required"),
  password: Yup.string().required("Required"),
  termsAndConditions: Yup.bool().oneOf(
    [true],
    "* Please check the above field"
  ),
});
const SignUp = () => {
  const navigate = useNavigate();
  const onSubmit = async (data, onSubmitProps) => {
    console.log(data);
    // alert(JSON.stringify(data, null, 2));

    try {
      let response = await API.SignUp({
        name: data.name,
        email: data.email,
        password: data.password,
      });
      console.log(response, "rsp");
      navigate(`/signin`);
      onSubmitProps.resetForm();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <section className="login-page " id="loginForm">
        <div className="loginOuter container">
          <div className="form-Layout">
            <h1 className="loginHeading">Sign Up For DNGH</h1>
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={validationSchema}
              enableReinitialize
            >
              <Form className="form-group login-form-layout" action="">
                <CustomFormInput
                  lableType="text"
                  labelName="UserName"
                  fieldName="name"
                  component={TextError}
                />

                <CustomFormInput
                  lableType="email"
                  labelName="Email"
                  fieldName="email"
                  component={TextError}
                />

                <CustomPasswordInput />

                <div className="signup-label ">
                  <div>
                    <Field
                      type="checkbox"
                      name="termsAndConditions"
                      className="checkbox-singup"
                    />
                  </div>

                  <div>
                    By creating a DNGH account, I agree to DNGH Terms and
                    Conditions, Privacy Policy, Cookies and Tracking Policy, and
                    to receive marketing emails
                  </div>

                  {/* {errors.termsAndConditions && <p>{errors.termsAndConditions}</p>} */}
                </div>
                <ErrorMessage
                  name={"termsAndConditions"}
                  component={TextError}
                />

                <Button type="submit" classData="btn loginButton">
                  Submit
                </Button>
                <p className="text-center mt-2 already-acc-login">
                  Already have an account?
                  <NavLink to="/signin" className="login-btn">
                    Login
                  </NavLink>
                </p>
              </Form>
            </Formik>
          </div>
        </div>
      </section>
    </>
  );
};

export default SignUp;
