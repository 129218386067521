import React from "react";
import { useState, useEffect } from "react";
import * as API from "../util/url";
import ProjectPagination from "../Common/Pagination/ProjectPagination";
import PageSize from "../Common/PageSize";
import Moment from "moment";
import TableComponent from "../Common/TableComponent/TableComponent";

const Bids = () => {
  const tableColumns = [
    {
      accessorKey: "productName",
      header: "Product Name",
      cell: (props) => <div>{props.getValue()}</div>,
    },
    {
      accessorKey: "type",
      header: "Product Type",
      cell: (props) => <div>{props.getValue()}</div>,
    },
    {
      accessorKey: "status",
      header: "Status",
      cell: (props) => <div>{props.getValue()}</div>,
    },
    {
      accessorKey: "auctionEndDate",
      header: "Auction End Date",
      cell: (props) => (
        <div>{Moment(props.getValue()).format("DD-MM-YYYY")}</div>
      ),
    },

    {
      accessorKey: "price",
      header: "	Price",
      cell: (props) => <div>${props.getValue()}</div>,
    },
  ];

  const handlePageSize = (data) => setPageSize(data);
  const [domainData, setDomainData] = useState([]);
  const [pageSize, setPageSize] = useState("10");
  const [pageCount, setPageCount] = useState(0);
  const [dataCount, setDataCount] = useState(0);

  const DomainDetail = async (pageNumber = 1, status, order) => {
    try {
      let response = await API.singleUserBiddingData({
        pageNumber: pageNumber,
        size: pageSize,
      });
      console.log(response, "rsp");

      setDomainData(response.data.map.biddingHistory);
      setPageCount(response.data.map.size / pageSize);
      setDataCount(response.data.map.size);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    DomainDetail();
  }, [pageSize]);
  return (
    <div>
      {/* <div className="container"> */}
      <div className="pagebody">
        <div className="page_tittle">
          <h3 className="px-3">Bidding Details</h3>
        </div>
        <div
          className="d-flex"
          style={{ justifyContent: "space-between", alignItems: "center" }}
        >
          <div className="showing">
            <h6>Showing {pageSize} items</h6>
          </div>
          <div className="searchcol px-3">
            <input
              className="searchinput"
              type="text"
              placeholder="Search.. "
            />
          </div>
        </div>
        <div className="container table-container mt-3">
          {/* Tanstack Table  */}

          <TableComponent
            data={domainData}
            columns={tableColumns}
            // sorting={sorting}
            // setSorting={setSorting}
            // filter={(e) => tableFilter(e)}
          />
          <div style={{ position: "relative", height: "50px" }}>
            {dataCount > pageSize && (
              <ProjectPagination
                handlePageClick={(e) => DomainDetail(e.selected + 1)}
                pageCount={pageCount}
              />
            )}

            <div className="pageSizeContainer">
              <PageSize
                title={`${pageSize} items`}
                handleData={(e) => handlePageSize(e)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Bids;
