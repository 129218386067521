import React, { useState } from "react";
import LandingNavbar from "../../../Common/LandingNavbar";
import Button from "../../../components/ui/Button";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { RiDeleteBin6Line } from "react-icons/ri";
import * as API from "../../../util/url";
import ApiCaller from "../../../util/ApiCaller";
import { toast } from "react-toastify";
// import img1 from "../../../image/Step1.png";
// import img2 from "../../../image/Step2.png";
// import img3 from "../../../image/listing3.png";

import "./MakeOffer.css";
import { Input } from "postcss";
import Footer from "../../../Common/Footer";
import KeyFeature from "../../Home/KeyFeature";
import SellBuy from "../../Home/SellBuy";
import { Modal } from "react-bootstrap";
// import listdomainbg from "../../../image/light_bg2.png";

const MakeOffer = () => {
  //   const tldRegex = /\.(.com|.net|.org|.edu|.info|.io|.co|.ai|.tv|.online|.club|.co.uk|.us|.to|.me)$/i;
  const tldRegex =
    /\.(com|net|org|edu|info|io|co|ai|tv|online|club|us|to|me|info)$/i;

  const [apiInfo, setApiInfo] = useState({
    path: "",
    redirectUrl: "",
  });
  const [domainStep, setDomainStep] = useState({
    step1: true,
    step2: false,
    step3: false,
  });
  const [domainStore, setDomainStore] = useState("");
  const [domainArray, setDomainArray] = useState([]);
  const [listDomain, setListDomain] = useState([]);

  const DomainStore = (value) => setDomainStore(value);

  const DomainSeprator = () => {
    handleShow();
    console.log(domainStore, "dsa");
    if (tldRegex.test(domainStore)) {
      console.log("Valid TLD:");
      domainStore
        .trim()
        .split(/\s+/)
        .forEach((data) =>
          setDomainArray((prev) => [
            ...prev,
            {
              domainName: data,
              buyNowPrice: "",
              minimumPrice: "",
              currency: "USD",
            },
          ])
        );
      setDomainStep({ step2: true });
    } else {
      handleClose();
      console.log("Invalid TLD:");
      toast("Invalid Domains", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        // theme: "colored",
        style: {
          backgroundColor: "rgba(255, 0, 0, 0.6)", // Custom background color
          color: "#ffffff", // Custom text color
        },
      });
    }
  };

  const deleteDomain = (domain) =>
    setListDomain(listDomain.filter((data) => data !== domain));

  const listingDomains = () => {
    console.log(listDomain, "ld");
    if (listDomain.length === 0) {
      toast("Oops..! Please enter your Domain price.", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "colored",
      });
      return;
    } else {
      let data = listDomain.filter((data) => {
        if (data.buyNowPrice === "" && data.minimumPrice === "") {
          return;
        }
        return data;
      });
      console.log(data);
      if (data.length !== 0) {
        setListDomain(data);
        setDomainStep({ step3: true });
      } else {
        alert("Oops..! Please enter your Domain price.");
      }
    }
  };

  const onChangeHandler = (price, domainName, key) => {
    let arrayManager = [];
    domainArray.map((data) => {
      if (domainName === data.domainName) {
        data[key] = price;
      }
      arrayManager.push(data);
    });
    setListDomain(arrayManager);
  };
  const buyNowDomainHandler = () => {
    console.log(listDomain, "ld");
    setApiInfo({
      path: () => API.buyNowDomains({ data: listDomain }),
      redirectUrl: "/dashboard/domain-portfolio",
    });
    handleClose();
  };

  const [show, setShow] = useState(false);

  // modal
  const handleClose = () => {
    setShow(false);
    setDomainStep({
      step1: true,
    });
    setDomainArray([]);
    setListDomain([]);
  };
  const handleShow = () => setShow(true);

  return (
    <div>
      <LandingNavbar />
      <div className="makeoffer-page">
        <div className="list-domain-section container">
          {/* <div className="listdomainbg-img container">
              <img src={listdomainbg} alt="" />
            </div> */}

          {/* step  1: Enter your desired domains */}
          <div className="listdomain-heading">
            <p>
              List Your Premium Domains for Sale in <br />
              <span className="text-highlight">Our Marketplace Today!</span>
              {/* Unlock great deals on premium <br /> domains for sale! */}
            </p>
          </div>
          {domainStep.step1 && (
            <div className="domainData">
              <div className="listdomain-field">
                {/* <div className="text-start domainData-heading">
                      Buy Domains
                    </div> */}
                <input
                  className="domainInput"
                  onChange={(e) => DomainStore(e.target.value)}
                  placeholder="Enter your Domain"
                  type="text"
                />

                <div className="d-flex mt-2" style={{ justifyContent: "end" }}>
                  <Button
                    type="button"
                    classData="btn listsearch-btn w-auto ms-2"
                    click={DomainSeprator}
                  >
                    {" "}
                    List Domain
                  </Button>
                </div>
              </div>
            </div>
          )}

          {/* step 2 */}
          {/* {domainStep.step2 && (
                <div className="domainData">
                  <div>
                    <div className="text-start">
                      Domain for listing({domainArray.length})
                    </div>
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Domain Name</th>
                          <th>Buy it Now</th>
                          <th>Make Offer</th>
                        </tr>
                      </thead>
                      <tbody>
                        {domainArray?.map((data, index) => (
                          <tr id="domainArrayTable">
                            <td>{index + 1}</td>
                            <td>{data.domainName}</td>
                            <td>
                              {" "}
                              <InputGroup className="priceWidth">
                                <InputGroup.Text id="basic-addon1">
                                  $
                                </InputGroup.Text>
                                <Form.Control
                                  type="number"
                                  min={0}
                                  aria-describedby="basic-addon1"
                                  onChange={(e) =>
                                    onChangeHandler(
                                      e.target.value,
                                      data.domainName,
                                      "buyNowPrice"
                                    )
                                  }
                                />
                              </InputGroup>
                            </td>
                            <td>
                              {" "}
                              <InputGroup className="priceWidth">
                                <InputGroup.Text id="basic-addon1">
                                  $
                                </InputGroup.Text>
                                <Form.Control
                                  type="number"
                                  min={0}
                                  aria-describedby="basic-addon1"
                                  onChange={(e) =>
                                    onChangeHandler(
                                      e.target.value,
                                      data.domainName,
                                      "minimumPrice"
                                    )
                                  }
                                />
                              </InputGroup>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <div className="d-flex" style={{ justifyContent: "end" }}>
                      <Button
                        type="button"
                        classData="btn loginButton w-auto"
                        click={listingDomains}
                      >
                        {" "}
                        Submit
                      </Button>
                    </div>
                  </div>
                </div>
              )} */}

          {/* {domainStep.step2 && (
                <div className="domainData">
                  <div>
                    <div className="text-start mb-4">
                      Domain for listing
                    
                    </div>
                    <div className="container">
                      <div>
                        <div>
                          <div className="row list-domains">
                          
                            <div className="col-4 col-md-4">Domain Name</div>
                            <div className="col-4 col-md-4">Buy it Now</div>
                            <div className="col-4 col-md-4">Make Offer</div>
                          </div>
                        </div>
                        <div className="">
                          {domainArray?.map((data, index) => (
                            <div className="row p-4" id="domainArrayTable">
                             
                              <div className="col-4 col-md-4 mt-2">
                                {data.domainName}
                              </div>
                              <div className="col-4 col-md-4">
                                {" "}
                                <InputGroup className="priceWidth">
                                  <InputGroup.Text id="basic-addon1">
                                    $
                                  </InputGroup.Text>
                                  <Form.Control
                                    type="number"
                                    className="numberfield"
                                    min={0}
                                    aria-describedby="basic-addon1"
                                    onChange={(e) =>
                                      onChangeHandler(
                                        e.target.value,
                                        data.domainName,
                                        "buyNowPrice"
                                      )
                                    }
                                  />
                                </InputGroup>
                              </div>
                              <div className="col-4 col-md-4">
                                {" "}
                                <InputGroup className="priceWidth">
                                  <InputGroup.Text id="basic-addon1">
                                    $
                                  </InputGroup.Text>
                                  <Form.Control
                                    type="number"
                                    className="numberfield"
                                    min={0}
                                    aria-describedby="basic-addon1"
                                    onChange={(e) =>
                                      onChangeHandler(
                                        e.target.value,
                                        data.domainName,
                                        "minimumPrice"
                                      )
                                    }
                                  />
                                </InputGroup>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="d-flex" style={{ justifyContent: "end" }}>
                      <Button
                        type="button"
                        classData="btn loginButton w-auto"
                        click={listingDomains}
                      >
                        {" "}
                        Submit
                      </Button>
                    </div>
                  </div>
                </div>
              )} */}

          {/* step 3 */}
          {/* {domainStep.step3 && (
                <div className="domainData">
                  <div>
                    <div className="text-start">
                      Domains for Preview ({listDomain.length})
                    </div>
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Domain Name</th>
                          <th>Buy it Now</th>
                          <th>Make Offer</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {listDomain?.map((data, index) => (
                          <tr id="domainArrayTable">
                            <td>{index + 1}</td>
                            <td>{data.domainName}</td>
                            <td>
                              {data.buyNowPrice === ""
                                ? "-"
                                : `$${data.buyNowPrice}`}
                            </td>
                            <td>
                              {data.minimumPrice === ""
                                ? "-"
                                : `$${data.minimumPrice}`}
                            </td>
                            <td onClick={() => deleteDomain(data)}>
                              <RiDeleteBin6Line />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <div className="d-flex" style={{ justifyContent: "end" }}>
                      <Button
                        type="button"
                        classData="btn loginButton w-auto"
                        click={buyNowDomainHandler}
                      >
                        {" "}
                        Submit
                      </Button>
                    </div>
                  </div>
                </div>
              )} */}
          {/* {domainStep.step3 && (
                <div className="domainData">
                  <div>
                    <div className="text-start mb-4">
                     
                      Domains for Preview
                    </div>
                    <div className="container">
                      <div>
                        <div className="row list-domains">
                          <div className="col-4 col-md-4">Domain Name</div>
                          <div className="col-3 col-md-3">Buy it Now</div>
                          <div className="col-3 col-md-3">Make Offer</div>
                          <div className="col-4 col-md-2">Action</div>
                        </div>
                      </div>
                      <div>
                        {listDomain?.map((data, index) => (
                          <div className="row p-3 mt-2" id="domainArrayTable">
                           
                            <div className="col-4 col-md-4">
                              {data.domainName}
                            </div>
                            <div className="col-4 col-md-3">
                              {data.buyNowPrice === ""
                                ? "-"
                                : `$${data.buyNowPrice}`}
                            </div>
                            <div className="col-4 col-md-3">
                              {data.minimumPrice === ""
                                ? "-"
                                : `$${data.minimumPrice}`}
                            </div>
                            <div
                              className="col-4 col-md-2"
                              onClick={() => deleteDomain(data)}
                            >
                              <RiDeleteBin6Line />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div
                      className="d-flex mt-3"
                      style={{ justifyContent: "end" }}
                    >
                      <Button
                        type="button"
                        classData="btn loginButton w-auto"
                        click={buyNowDomainHandler}
                      >
                        {" "}
                        Submit
                      </Button>
                    </div>
                  </div>
                </div>
              )} */}

          {/* <Button variant="primary" onClick={handleShow}>
            Launch demo modal
          </Button> */}

          <Modal
            show={show}
            onHide={handleClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="domain-modal"
          >
            <Modal.Header closeButton>
              {domainStep.step2 && (
                <div className="text-start fs-5">Domain for Listing</div>
              )}
              {domainStep.step3 && (
                <div className="text-start fs-5">Domain for Preview</div>
              )}
            </Modal.Header>
            <Modal.Body>
              {/* step2  */}

              {domainStep.step2 && (
                <div className="domainData">
                  <div className="dominlist">
                    {/* <div className="text-start mb-4">Domain for Listing</div> */}
                    <div className="domain-listing-table">
                      <div className="domain-listing-heading">
                        <div>Domain Name</div>
                        <div>Buy it Now</div>
                        <div>Make Offer</div>
                      </div>
                      <div className="domain-listing-headingbody">
                        {domainArray?.map((data, index) => (
                          <div className="domain-listing-row" key={index}>
                            <div className="mt-2 domain-name">
                              {data.domainName}
                            </div>
                            <div>
                              <InputGroup className="priceWidth">
                                <InputGroup.Text id="basic-addon1">
                                  $
                                </InputGroup.Text>
                                <Form.Control
                                  type="number"
                                  className="numberfield"
                                  min={0}
                                  aria-describedby="basic-addon1"
                                  onChange={(e) =>
                                    onChangeHandler(
                                      e.target.value,
                                      data.domainName,
                                      "buyNowPrice"
                                    )
                                  }
                                />
                              </InputGroup>
                            </div>
                            <div>
                              <InputGroup className="priceWidth">
                                <InputGroup.Text id="basic-addon1">
                                  $
                                </InputGroup.Text>
                                <Form.Control
                                  type="number"
                                  className="numberfield"
                                  min={0}
                                  aria-describedby="basic-addon1"
                                  onChange={(e) =>
                                    onChangeHandler(
                                      e.target.value,
                                      data.domainName,
                                      "minimumPrice"
                                    )
                                  }
                                />
                              </InputGroup>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div
                      className="d-flex mt-3"
                      style={{ justifyContent: "end" }}
                    >
                      <Button
                        type="button"
                        classData="btn loginButton w-auto"
                        click={listingDomains}
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </div>
              )}

              {/* step 3 */}
              <div style={{ height: "100%" }}>
                {domainStep.step3 && (
                  <div className="domainData">
                    <div className="dominlist">
                      {/* <div className="text-start mb-4">Domains for Preview</div> */}
                      <div className="domain-listing-table">
                        <div className="domain-listing-header">
                          <div>Domain Name</div>
                          <div>Buy it Now</div>
                          <div>Make Offer</div>
                          <div className="pe-3">Action</div>
                        </div>
                        <div className="domain-listing-body">
                          {listDomain?.map((data, index) => (
                            <div className="domain-listing-row" key={index}>
                              <div className="domain-name">
                                {data.domainName}
                              </div>
                              <div>
                                {data.buyNowPrice === ""
                                  ? "-"
                                  : `$${data.buyNowPrice}`}
                              </div>
                              <div>
                                {data.minimumPrice === ""
                                  ? "-"
                                  : `$${data.minimumPrice}`}
                              </div>
                              <div
                                onClick={() => deleteDomain(data)}
                                className="pe-3"
                              >
                                <RiDeleteBin6Line />
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div
                        className="d-flex mt-4"
                        style={{ justifyContent: "end" }}
                      >
                        <Button
                          type="button"
                          classData="btn loginButton w-auto"
                          click={buyNowDomainHandler}
                        >
                          Submit
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Modal.Body>
          </Modal>
        </div>

        <div className="container domain-sell">
          <div className="domain-sell-heading">
            Sell Your Domain in Two Simple Steps
          </div>
          <div className="line" />
          {/* </div> */}

          <div>
            <div
              // className="list-group vertical-steps  text-start row"
              className="row "
              // style={{ marginTop: "5rem", marginBottom: "6rem" }}
            >
              <div
                //  className="list-group-item col-12 col-md-4 "
                className="col-12 col-md-6 "
              >
                <div className="buyer-steps">
                  {/* <img src={img1} alt="" /> */}
                  <h6
                    style={{
                      fontWeight: 600,
                      marginTop: "30px",
                      fontSize: "20px",
                      marginBottom: "15px",
                    }}
                  >
                    Step 1
                  </h6>
                  {/* 1. Portfolio is where you can list bulk of domains <br /> */}
                  {/* Input your domain name and details about your domain */}
                  <p>
                    {" "}
                    Enter the full domain and your desired price for the domain
                    in the designated field. If you prefer to negotiate, select
                    “Make Offer”.This allows buyers to propose their own offers.
                  </p>
                </div>
              </div>
              <div className=" col-12 col-md-6 ">
                <div className="buyer-steps">
                  {/* <img src={img2} alt="" /> */}
                  <h6
                    style={{
                      fontWeight: 600,
                      marginTop: "30px",
                      fontSize: "20px",
                      marginBottom: "15px",
                    }}
                  >
                    Step 2
                  </h6>
                  {/* Buyer has to pay for instant purchase */}
                  <p>
                    {" "}
                    Please check that all details, including the domain name and
                    price, are correct. Then click 'Submit' to receive a
                    successfully message.
                  </p>
                </div>
              </div>
              {/* <div className=" col-12 col-md-4 ">
                  <div className="buyer-steps">
                    <img src={img3} alt="" />
                    <h6
                      style={{
                        fontWeight: 700,
                        marginTop: "30px",
                        fontSize: "25px",
                      }}
                    >
                      Step 3
                    </h6>
                   
                    <p>
                      {" "}
                      Please check that all details, including the domain name
                      and price, are correct. Then click 'Submit' to receive a
                      successfully message.
                    </p>
                  </div>
                </div> */}
            </div>
          </div>
        </div>
        <SellBuy />
        <KeyFeature />
        <Footer />
      </div>

      <ApiCaller apiData={apiInfo} />
    </div>
  );
};

export default MakeOffer;
