import { createSlice } from "@reduxjs/toolkit";

const bidDomainUrlSlice = createSlice({
    name: "bid",
    initialState:{
        bidUrl:''
    },
    reducers:{
        setBidUrl: (state, { payload }) => {
            state.bidUrl = payload;
        
          },
    }
})

export const {setBidUrl} = bidDomainUrlSlice.actions
export default bidDomainUrlSlice.reducer;